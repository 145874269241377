import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import InvoiceHeader from "./InvoiceHeader";
import InvoiceDescription from "./InvoiceDescription";
import { H6 } from "../../../../../../AbstractElements";
import PrintInvoice from "./PrintInvoice";



const ItemDescription = (props) => {

  const dados = props.dados;

  if (!dados) {
    return (
      <Container>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className="text-center">
                  <p>Carregando dados...</p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <Container>
        <Row>
          <Col >
            <Card>
              <CardBody>
                <div className='invoice'>
                  <div>
                    <InvoiceHeader dados={dados} />
                    <InvoiceDescription />
                    <div className='table-responsive invoice-table' id='table'>
                      <Table bordered striped>
                        <tbody>
                          <tr>
                            <td className='item'>
                              <H6 attrH6={{ className: 'p-2 mb-0' }}>{'Nome do produto'}</H6>
                            </td>
                            <td className='quantity'>
                              <H6 attrH6={{ className: 'p-2 mb-0' }}>{'Quantidade'}</H6>
                            </td>
                            <td className='Rate'>
                              <H6 attrH6={{ className: 'p-2 mb-0' }}>{'Preço'}</H6>
                            </td>
                            <td className='subtotal'>
                              <H6 attrH6={{ className: 'p-2 mb-0' }}>{'Sub total'}</H6>
                            </td>
                          </tr>
                          {dados.items.map((item, index) => (
                            <tr>
                              <td className='item'>
                                <H6 attrH6={{ className: 'p-2 mb-0' }}>{item.name}</H6>
                              </td>
                              <td className='quantity'>
                                <H6 attrH6={{ className: 'p-2 mb-0' }}>{item.quantity}</H6>
                              </td>
                              <td className='Rate'>
                                <H6 attrH6={{ className: 'p-2 mb-0' }}>{item.unit_amount / 100}</H6>
                              </td>
                              <td className='subtotal'>
                                <H6 attrH6={{ className: 'p-2 mb-0' }}>{ (item.unit_amount / 100) * item.quantity}</H6>
                              </td>
                            </tr>
                          ))}

                        </tbody>
                      </Table>
                    </div>
                    <PrintInvoice  dados={dados}/>

                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ItemDescription;
