import React, { useEffect, useState } from 'react';
import NpsChart from './NpsChart'; 
import companyService from '../../../../../Services/administrativo/company.service';
import { CompanyNpsResponse } from '../../../../../@types/response/company.nps.response';
import { CompanyResponse } from '../../../../../@types/response/company.response';

const CrmMinhaLojaNPS: React.FC = () => {
  var companyString: string | null = localStorage.getItem('company');
  let companyLocal: CompanyResponse;

  if (companyString) {
    companyLocal = JSON.parse(companyString);
  } else {
    console.error("Nenhum dado encontrado no localStorage para 'company'");
  }

  const [companyNpsResponse, setCompanyNpsResponse] = useState<CompanyNpsResponse>();
  const [loading, setLoading] = useState(true);

  const fetchCompanyData = async () => {
    try {

      const resp = await companyService.getDadosNps(companyLocal.id);
      const item = resp.data;

      const companyNpsResponseFetch: CompanyNpsResponse = ({
        promoters: item.promoters,
        passives: item.passives,
        detractors: item.detractors
      });

      setCompanyNpsResponse(companyNpsResponseFetch);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (companyLocal) {
      fetchCompanyData();
    }
  }, [])



  return (loading ? 'Carregando' :

    <NpsChart promoters={companyNpsResponse!.promoters} passives={companyNpsResponse!.passives} detractors={companyNpsResponse!.detractors} />

  );
};

export default CrmMinhaLojaNPS;
