import React, { useEffect, useState } from 'react';
import clienteService from '../../../../../Services/administrativo/cliente.service';
import { H3 } from '../../../../../AbstractElements';
import DataTable from 'react-data-table-component';
import { extratoResgateClienteColumns } from '../../../../../Data/crm/ExtratoResgateClienteList';
import SearchNotFoundClass from '../../../../Common/CommonWidgets/SearchNotFoundClass';

const ExtratoResgateClienteList = ({ cliente, dados, loading }) => {
  
    

    return (
        loading ? (
            'Carregando'
        ) : dados.length === 0 ? (
            <SearchNotFoundClass message="Não foi encontrado nenhum registro de resgate para esse cliente!" />
        ) : (
            <>
                <H3>Resgates</H3>
                <DataTable
                    noHeader
                    columns={extratoResgateClienteColumns}
                    data={dados}
                    highlightOnHover={true}
                    striped={true}
                    responsive={true}
                />
            </>
        )
    );
};

export default ExtratoResgateClienteList;
