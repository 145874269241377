import React, { Fragment, } from "react";
import { Breadcrumbs, } from "../../../../../AbstractElements";
import { Col, Container, Row, } from "reactstrap";
import CrmMinhaLojaDiaDeFuncionamento from "./DiasDeFuncionamento";
import CrmMinhaLojaRegrasDePontuacao from "./RegrasDePontuacao"; 
import CrmMinhaLojaRegrasDeResgate from "./RegrasDeResgate";

const CrmMinhaLojaParametrizacao = () => { 


  return (
    <> <Fragment>
      <Breadcrumbs mainTitle='Parametrizações' parent='CRM' title='Minha loja' />
      <Container fluid={true}>

        <Row>
          <Col md={5}>
            <CrmMinhaLojaRegrasDePontuacao />
           
          </Col>
          <Col l md={7}>
          <CrmMinhaLojaRegrasDeResgate />
          </Col>
        </Row>
        <Row>
        <Col ><CrmMinhaLojaDiaDeFuncionamento />
        </Col></Row>

      </Container>
    </Fragment></>
  );
};

export default CrmMinhaLojaParametrizacao; 