import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs, } from "../../../../../AbstractElements";
import { Card, CardBody, CardHeader, Col, Container, Row, } from "reactstrap";
import companyService from "../../../../../Services/administrativo/company.service";
import ExtratoSms from "./ExtratoSms";
import SaldoSms from "./SaldoSms";
import ExtratoPagamentoSms from "./ExtratoPagamentoSms";
import { Bar } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { PlusCircle } from "react-feather";

const CrmMinhaLojaSms = () => {
    var companyLocal = JSON.parse(localStorage.getItem('company'));

    const [saldoAtual, setSaldoAtual] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCompanySaldoAtual = async () => {
            try {
                const response = await companyService.getSaldoSms(companyLocal.id);
                const data = await response.data;
                setSaldoAtual(data);
                setLoading(false);
            } catch (error) {
                console.error('Error to load options:', error);
            }
        };
        fetchCompanySaldoAtual();
    }, []);

 

    return (loading ? 'Carregando' : 
        <>
            <Breadcrumbs mainTitle='Sms' parent='CRM' title='Minha loja' />

            <Container fluid={true}>
                <Row>
                    {/* Card de Saldo Atual */}
                    <Col md={4}>
                        <Card className="h-100">
                            <CardHeader>
                                <h4>Saldo Atual</h4>
                            </CardHeader>
                            <CardBody>
                                <div className="text-center mb-4">
                                    <h2 className="display-4 text-success">
                                        {saldoAtual == null ? "Carregando..." : `R$ ${saldoAtual.toFixed(2)}`}
                                        <br/>
                                        
                                    </h2>
                                    <small className="text-muted">{saldoAtual == null ? '' : (saldoAtual  / 0.12) + ' sms disponíveis'} </small>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    {/* Card de Resumo de SMS */}
                    <Col md={4}>
                        <Card className="h-100">
                            <CardHeader>
                                <h4>Resumo de SMS</h4>
                            </CardHeader>
                            <CardBody>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        Total enviados este mês: <strong>150</strong>
                                    </li>
                                    <li className="list-group-item">
                                        SMS gratuitos: <strong>50</strong>
                                    </li>
                                    <li className="list-group-item">
                                        SMS pagos: <strong>100</strong>
                                    </li>
                                    <li className="list-group-item">
                                        Última recarga: <strong>01/12/2024</strong>
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>

                    {/* Card de Ações Rápidas */}
                    <Col md={4}>
                        <Card className="h-100">
                            <CardHeader>
                                <h4>Ações Rápidas</h4>
                            </CardHeader>
                            <CardBody className="text-center d-flex flex-column justify-content-center">
                                <Link
                                    className="btn btn-primary btn-lg mb-3"
                                    to={`${process.env.PUBLIC_URL}/crm/minhaloja/comprarsms`}
                                >
                                    Comprar SMS
                                </Link>
                                <Link
                                    className="btn btn-success btn-lg"
                                    to={`${process.env.PUBLIC_URL}/crm/minhaloja/extratocomprassms`}
                                >
                                    Extrato de Pagamentos
                                </Link>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>  
            <br/>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <h4>SMS enviados</h4>
                            </CardHeader>


                            <CardBody>
                                <ExtratoSms />
                            </CardBody>
                        </Card>

                    </Col>

                </Row>


            </Container> </>
    );
};

export default CrmMinhaLojaSms; 