import React, { useState, useEffect  } from 'react';
import { Row, Col, FormGroup, Label, } from 'reactstrap';
import companyService from '../../../../Services/administrativo/company.service';
import perfilService from '../../../../Services/administrativo/perfil.service';
import ModuleSystemService from '../../../../Services/utils/moduleSystem.service';
import userPerfilService from '../../../../Services/administrativo/userPerfil.service';
import DataTable from 'react-data-table-component';
import { userPerfilColumns } from '../../../../Data/administrativo/UserPerfilList';

const UserPerfilForm = (props) => {
  const user = props.user;
  const [module, setModule] = useState(null); 
  const [listOfCompany, setListOfCompany] = useState([]);
  const [listOfPerfil, setListOfPerfil] = useState([]);
  const [listOfModule, setListOfModule] = useState([]);
  const [perfilOfUser, setPerfilOfUser] = useState([]);
  const [formData, setFormData] = useState({ companyId: null, perfilId: null, userId: user.id });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Process the form data
    console.log('Form submitted:', formData);
    if (formData.companyId === null || formData.perfilId === null) {
      console.log('Campo obrigatório');
    } else {
      userPerfilService.insert(formData);
      refreshUserPerfil();
      

      //setFormData({ companyId: null, perfilId: null  }); // Reset the form
    }



  };


  useEffect(() => {
    const fetListOfCompany = async () => {
      try {
        const response = await companyService.getAll();
        const data = await response.data;
        setListOfCompany(data);
      } catch (error) {
        console.error('Error to load options:', error);
      }
    };
    fetListOfCompany();
  }, []);



  useEffect(() => {
    const fetListOfPerfil = async () => {
      try {
        const response = await perfilService.getAll();
        const data = await response.data;
        setListOfPerfil(data);
      } catch (error) {
        console.error('Error to load options:', error);
      }
    };
    fetListOfPerfil();
  }, []);


  const refreshUserPerfil = async () => {
    try {
      const response = await userPerfilService.getAll(user.id);
      const data = await response.data;
      setPerfilOfUser(data); 
    } catch (error) {
      console.error('Error to load options:', error);
    }
  }

  useEffect(() => {
    const fetListOfPerfilOfUser = async () => {
      refreshUserPerfil();
    };
    fetListOfPerfilOfUser();
  }, []);


  useEffect(() => {
    const fetListOfModule = async () => {
      try {
        const response = await ModuleSystemService.getAll();
        const data = await response.data;
        setListOfModule(data);
      } catch (error) {
        console.error('Error to load options:', error);
      }
    };
    fetListOfModule();
  }, []);

  return (
    <>
     

{
  props.option === 'NewPerfil' ? 
      <form className="theme-form mega-form" id="formCountry" name="formCountry" method="POST" onSubmit={handleSubmit}>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label className="col-form-label">Empresa:</Label>
              <select className='form-control' name="companyId" size='1' value={formData.companyId} onChange={handleChange}>
                <option >Selecione</option>
                {listOfCompany.map((item) => (
                  <option value={item.id} key={item.id} selected={item.id === formData.companyId} >{item.person.name}</option>
                ))}
              </select>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="col-form-label">Módulo:</Label>
              <select className='form-control' size='1' value={module} onChange={(e) => { setModule(e.target.value);  }}>
                <option value={null} >Selecione</option>
                {listOfModule.map((item) => (
                  <option value={item} key={item} selected={item === module} >{item}</option>
                ))}
              </select>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="col-form-label">Perfil:</Label>
              <select className='form-control' size='1' name="perfilId" value={formData.perfilId} onChange={handleChange}>
                <option >{module !== null ? 'Selecione' : 'Selecione um módulo'}</option>
                {module !== null ? listOfPerfil.filter(f => f.module === module).map((item) => (
                  <option value={item.id} key={item.id} selected={item.id === formData.companyId} >{item.name}</option>
                )) : ''}
              </select>
            </FormGroup>
          </Col>
        </Row>
        <div>
          <button type="submit">Enviar</button>
        </div>

      </form> : '' }
      
      <div className='table-responsive product-table'>
        <DataTable noHeader pagination paginationServer columns={userPerfilColumns} data={perfilOfUser} highlightOnHover={true} striped={true} responsive={true} />
      </div>
      </>
  );
};
export default UserPerfilForm;
