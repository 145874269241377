import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Breadcrumbs } from '../../../../../AbstractElements';
import { Card, CardBody, Container } from 'reactstrap';
 
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface NpsChartProps {
    promoters: number;
    passives: number;
    detractors: number;
}

const NpsChart: React.FC<NpsChartProps> = ({ promoters, passives, detractors }) => {
    const totalResponses = promoters + passives + detractors;
    const nps = Math.round(((promoters - detractors) / totalResponses) * 100);

    const data = {
        labels: ['Promotores', 'Passivos', 'Detratores'],
        datasets: [
            {
                label: 'Respostas',
                data: [promoters, passives, detractors],
                backgroundColor: ['#4caf50', '#ffeb3b', '#f44336'],
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top' as const,
            },
            tooltip: {
                callbacks: {
                    label: (context: any) => `${context.raw} respostas`,
                },
            },
        },
    };

    const formatPercentage = (value: number) => ((value / totalResponses) * 100).toFixed(1);

    return (
        <> 
            <Breadcrumbs mainTitle={'NPS Score: ' + nps } parent='CRM' title='Minha loja' />
            <Container fluid={true}> 
                <Card className='text-center'>
                    <CardBody>
                        <p>
                            O Net Promoter Score (NPS) mede a lealdade do cliente com base em respostas categorizadas:
                            <strong> Promotores</strong> (9-10), <strong>Passivos</strong> (7-8), e{' '}
                            <strong>Detratores</strong> (0-6).
                        </p>

                        <div style={{ display: 'flex', justifyContent: 'space-around', margin: '20px 0' }}>
                            <div>
                                <h3 style={{ color: '#4caf50' }}>Promotores</h3>
                                <p>{promoters} respostas</p>
                                <p>{formatPercentage(promoters)}%</p>
                            </div>
                            <div>
                                <h3 style={{ color: '#ffeb3b' }}>Passivos</h3>
                                <p>{passives} respostas</p>
                                <p>{formatPercentage(passives)}%</p>
                            </div>
                            <div>
                                <h3 style={{ color: '#f44336' }}>Detratores</h3>
                                <p>{detractors} respostas</p>
                                <p>{formatPercentage(detractors)}%</p>
                            </div>
                        </div>
                        <div style={{ width: '70%', margin: '0 auto', textAlign: 'center' }}>
                            <Bar data={data} options={options} />

                        </div> 
                        <div style={{ marginTop: '20px', textAlign: 'left' }}>
                            <h3>Ações Recomendadas</h3>
                            <ul>
                                <li>
                                    <strong>Promotores:</strong> Continue oferecendo um excelente serviço e peça
                                    que recomendem sua empresa.
                                </li>
                                <li>
                                    <strong>Passivos:</strong> Identifique formas de transformar passivos em
                                    promotores, ouvindo suas sugestões.
                                </li>
                                <li>
                                    <strong>Detratores:</strong> Descubra os principais problemas e implemente
                                    melhorias para evitar experiências negativas.
                                </li>
                            </ul>
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </>
    );
};

export default NpsChart;
