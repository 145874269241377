import React, { useState } from 'react';
import { Col, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { Alerts, H4, H6 } from '../../../../../AbstractElements';
import EnviarMensagemGeralSmsForm from './EnviarMensagemGeralSmsForm';
import EnviarMensagemGeralEmailForm from './EnviarMensagemGeralEmailForm';
import EnviarMensagemGeralPushNotificationForm from './EnviarMensagemGeralPushNotificationForm';

const EnviarMensagemGeral  = ({ toggle, modal, cliente, requestFilter, tipo , total}) => {

    const getDescricaoTituloTipo = () => {
        if (tipo === 'SMS') {
            return "Enviando SMS para " + total + ' cliente(s)';
        } else if (tipo === 'EMAIL') {
            return "Enviando EMAIL para " + total + ' cliente(s)';
        } else if (tipo === 'PUSH') {
            return "Enviando PUSH para " + total + ' cliente(s)';
        }
    };

    return (<Modal isOpen={modal} toggle={toggle} className='modal-body' centered={true} size='xl'>

        <ModalHeader toggle={toggle}>
            <b>{getDescricaoTituloTipo()}</b>
        </ModalHeader>


        <ModalBody>
            {
                !cliente ? <Alerts attrAlert={{ color: 'secondary ' }} >
                    <H4 attrH4={{ className: 'alert-heading' }} >Desculpe!</H4>
                    <H6 attrH6={{ className: 'alert-heading' }} >Não existe cliente para enviar mensagem!</H6>
                </Alerts> : 
                <Row>
                    <Col className='xl-100'>
                        {
                            tipo === 'SMS' ? <EnviarMensagemGeralSmsForm  filter={requestFilter} /> : ''
                        }
                        { 
                            tipo === 'EMAIL' ? <EnviarMensagemGeralEmailForm  filter={requestFilter} /> : ''
                        }
                        {
                            tipo === 'PUSH' ? <EnviarMensagemGeralPushNotificationForm    filter={requestFilter} /> : ''
                            
                        }

                    </Col>
                </Row>
            }

        </ModalBody>
    </Modal>
    );
};
export default EnviarMensagemGeral;