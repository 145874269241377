import { Image, H4, H6, P } from '../../../../../../AbstractElements';
import { ProjectDescription, ProjectDescriptionDetails } from '../../../../../../Constant';
import { Col, Media, Row } from 'reactstrap';
import React, { Fragment } from 'react'; 
import downloadService from '../../../../../../Services/utils/download.service';

const InvoiceDescription = (props) => {
 
  var companyLocal = JSON.parse(localStorage.getItem('company'));

  console.log(companyLocal);


  return (
    <Fragment>
      <Row className='invo-profile'>
        <Col xl='4'>
          <div className='invo-profile-left'>
            <Media>
              <div className='media-left'>
                <Image attrImage={{ className: 'media-object rounded-circle img-60', src: `${downloadService.getImagem(companyLocal.pciture)}`, alt: '' }} />
              </div>
              <Media body className='m-l-20'>
                <H4 attrH4={{ className: 'media-heading f-w-600' }}>{companyLocal.name}</H4>
                <P>
                  {companyLocal.name}
                  <br />
                  <span className='digits'>{companyLocal.cpfCnpj}</span>
                </P>
              </Media>
            </Media>
          </div>
        </Col>
        <Col xl='8'>
          <div className='text-md-end'>
            <div id='project'>
              <H6>{ProjectDescription}</H6>
              <P>{ProjectDescriptionDetails}</P>
            </div>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};
export default InvoiceDescription;
