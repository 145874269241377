import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import AlertError from '../../../../../CommonElements/AlertError';
import { crmListaClientesColumns } from '../../../../../Data/crm/CrmListaClientes';
import SearchNotFoundClass from '../../../../Common/CommonWidgets/SearchNotFoundClass';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

const AllClientes = (props) => {
  const clients = props.clients;

  
  

  const [currentPage, setCurrentPage] = useState(1); // Página atual
  const [rowsPerPage, setRowsPerPage] = useState(10); // Registros por página
  const totalRows = clients.length; // Total de registros
  const totalPages = Math.ceil(totalRows / rowsPerPage); // Total de páginas

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  // Muda o número de registros por página
  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1); // Volta para a primeira página
  };

  if (!clients) {
    return (
      <Container>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className="text-center">
                  <p>Carregando dados...</p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

  console.log(clients);

  return (clients.length === 0 ? <SearchNotFoundClass message={'Não foi encontrado nenhum cliente com os filtros informados! '} /> :
    <>
      <div className='table-responsive product-table'>{
        props.error ? (
          <AlertError title={'Sorry!'} message={props.error.message} />
        ) :
          props.loading ? (
            <p>Loading...</p>
          ) : <>

            <table className="table table-bordered table-striped">
              <thead className="thead-dark">
                <tr>
                  {crmListaClientesColumns.map((column) => (
                    <th key={column.selector} className={column.name === "Ações" ? "compact-column" : ""}s>{column.name}</th>
                  ))}
                </tr>

              
                {clients.map((client, index) => (
               
                   
               <tr key={index}>
                  {crmListaClientesColumns.map((column) => (
                    <td key={column.selector}>{column.selector(client)}</td>
                  ))}
                 
                </tr>
              ))}
               
              </thead>
            </table>

            {/* Paginação */}
            <div className="pagination d-flex justify-content-between align-items-center">
              <div>
                Registros por página:
                <select
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  className="form-control d-inline-block w-auto ml-2"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                </select>
              </div>
              <div>
                Página {currentPage} de {totalPages}
                <button
                  className="btn btn-primary ml-2"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Anterior
                </button>
                <button
                  className="btn btn-primary ml-2"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Próxima
                </button>
              </div>
            </div>
 </>
      }

      </div>

    </>

  );
};

export default AllClientes; 