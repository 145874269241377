import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { H5 } from '../../../../AbstractElements';
import {  } from '../../../../Constant';
import { OptionsVisitorChart } from '../../../../Data/Ecommerce/Chart';
import SvgIcon from '../../../Common/Component/SvgIcon'; 
import CrmDashboardService from '../../../../Services/crm/crm.dashboard.service';

const VisitorsCard = () => {

  const company = JSON.parse(localStorage.getItem('company'));
  const [series , setSeries] = useState([])
  const [loading, setLoading] = useState(true);

  
  const getListVisitantePorDiaDaSemana = async () => { 
    try {
      const response = await CrmDashboardService.getListVisitantePorDiaDaSemana(company.id);
      const data =  response.data;
      setSeries([{
        "name" : "%",
        data : data
      }]);
 
      setLoading(false);
      console.log(series);
    } catch (error) {
      setLoading(false);
    }
  }


  useEffect(() => {
    const getVisitantesPorDiaDaSemana = async () => {
      setLoading(true);
      getListVisitantePorDiaDaSemana();
      setLoading(false);
    };
    getVisitantesPorDiaDaSemana();
  }, [])

  return (
    <Card className='visitor-card'>
      <CardHeader className='card-no-border'>
        <div className='header-top'>
          <H5 attrH5={{ className: 'm-0' }}>
            Visitantes
            <span className='f-14 font-primary f-w-500 ms-1'>
              <SvgIcon iconId='user-visitor' className='svg-fill me-1' /> 
            </span>
          </H5>
         
        </div>
      </CardHeader>
      <CardBody className='pt-0'>
        <div className='visitors-container'>
          { loading ? 'Carregando' : series.length > 0 ? (
            <ReactApexChart height={270} type='bar' options={OptionsVisitorChart.options} series={series} /> ) : <></>
          }
        </div>
      </CardBody>
    </Card>
  );
};

export default VisitorsCard;
