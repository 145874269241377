import axios from "axios";
import authHeader from "../auth-header";
import config from '../../Config/AppConfig'; 

const API_URL = config.data.API_URL+"/fanfave-api/companyClient";

 class ClientService   {
   

  find(request: any, companyId : number) {    
    return axios
      .get(API_URL+ '/findByCodeQr/'+request + '/'+companyId,  { headers: authHeader() });
  }

  findById(clientId : number, companyId : number) {    
    return axios
      .get(API_URL+ '/'+clientId + '/'+companyId,  { headers: authHeader() });
  }

  novaListaAll(companyId: number, request :any, page : number, size : number, sortColumn : number, sortDirection : string) {    
    return axios
      .post(API_URL+ '/listarClientes/'+companyId + "?page="+ page + "&size=" + size + '&sort = ' + `${sortColumn},${sortDirection}`, request , { headers: authHeader() });
  }

  pontuar(request: any) {    
    return axios
      .post(API_URL+ '/pontuar', request,  { headers: authHeader() });
  }

  resgatar(request :any) {    
    return axios
      .post(API_URL+ '/resgatar', request,  { headers: authHeader() });
  }

  extractPoints(clienteId : number) {    
    return axios
      .get(API_URL+ '/extractPoints/'+clienteId,  { headers: authHeader() });
  }

  save(request : any, companyId : number) {    
    return axios
      .post(API_URL+ '/'+companyId, request, { headers: authHeader() });
  } 

  getFormaCadastro(formCadastro : string) {    
    if (formCadastro === 'VIA_APP') return 'Via aplicativo';
    else if (formCadastro === 'IMPORTACAO') return 'Via importação';
    else if (formCadastro === 'VIA_SISTEMA') return 'Via Sistema';
    else return formCadastro;
  } 
 

  enviarEmail(companyId : number, request : any) {
    return axios
      .post(API_URL+ '/enviarEmail/'+companyId, request, { headers: authHeader() });
  }

  enviarWhatsapp(companyId : number, request : any) {
    return axios
      .post(API_URL+ '/enviarWhatsapp/'+companyId, request, { headers: authHeader() });
  }

  enviarPushNotificationIndividual(companyId : number, request : any) {
    return axios
      .post(API_URL+ '/enviarPushNotificationIndividual/'+companyId, request, { headers: authHeader() });
  }

  enviarPushNotificationGeral(companyId : number, request : any) {
    return axios
      .post(API_URL+ '/enviarPushNotificationGeral/'+companyId, request, { headers: authHeader() });
  }

  enviaSms(companyId : number, request : any) {
    return axios
      .post(API_URL+ '/enviarSms/'+companyId, request, { headers: authHeader() });
  }

  getPontuacoes( clienteId : number) {
    return axios
      .get(API_URL+ '/extratoPontuacao/'+clienteId, { headers: authHeader() });
  }

  getResgates( clienteId : number) {
    return axios
      .get(API_URL+ '/extratoResgates/'+clienteId, { headers: authHeader() });
  }
}  
 
export default new ClientService();    
 