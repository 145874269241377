import React, {  useEffect, useState } from 'react';
import SvgIcon from '../../../Components/Common/Component/SvgIcon'; 
import { Link, useNavigate } from 'react-router-dom';
import DateService from '../../../Services/utils/date.service';
import SweetAlert from 'sweetalert2';

const Notificationbar = () => {
  const [notificationDropDown, setNotificationDropDown] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useNavigate();
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);  
 
  
    useEffect(() => {
      carregaNotificacao();
      setLoading(false);
    }, []);
  
    

 const  carregaNotificacao = async ()  => {
  let user = JSON.parse(localStorage.getItem("user"));
  if (!user) {
    SweetAlert.fire({
      title: 'Desculpe?',
      text: 'Sua sessão foi expirada! Efetue novamente o login!',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonText: 'Ok'
  })
      .then((result) => {
        history(`${process.env.PUBLIC_URL}/login`);
      });
  } else {
    setNotifications(user.notificacoes);
  }
 }
  
  return (loading ? 'Carregando' : 
    <li className='onhover-dropdown'>
      <div className='notification-box' onClick={() => setNotificationDropDown(!notificationDropDown)}>
        <SvgIcon iconId='notification' />
        <span className='badge rounded-pill badge-secondary' id={'badgeNotification' + user.id}>{notifications && notifications.length === 0 ? '0' : notifications.length}</span>

      </div>
      <div className={`notification-dropdown onhover-show-div ${notificationDropDown ? 'active' : ''}`}>
        <h6 className='f-18 mb-0 dropdown-title'>{'Notificações'}</h6>
        <ul>
          {notifications.map((notificacao, i) => (
            <li className='b-l-primary border-4' key={i}>
              <p>
                {notificacao.subject} <span className='font-danger'>{ DateService.getDateTime(notificacao.dateCreated)}</span>
              </p>
            </li>
          ))}

          <li>
            <Link
              to={`${process.env.PUBLIC_URL}/notificacoes`}
            >
              Ver todas
            </Link>


          </li>
        </ul>
      </div>
    </li>
  );
};

export default Notificationbar;
