import { useEffect, useRef, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn } from "../../../../../../AbstractElements";
import SaldoSms from "../SaldoSms";
import ExtratoPagamentoSms from "../ExtratoPagamentoSms";
import pagamentoService from "../../../../../../Services/pagamento.service";
import { CompanyResponse } from "../../../../../../@types/response/company.response";
import { CheckoutResponse } from "../../../../../../@types/response/checkout.response";
import { SmsOrderResponse } from "../../../../../../@types/response/sms.order.response";
import DateService from "../../../../../../Services/utils/date.service";
import { Link } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';
import { FiSave } from "react-icons/fi";



const CrmMinhaLojaExtratoComprasSms = () => {

    var companyString: string | null = localStorage.getItem('company');
    let companyLocal: CompanyResponse;

    const componentRef = useRef<HTMLDivElement>(null);


    if (companyString) {
        companyLocal = JSON.parse(companyString);
    } else {
        console.error("Nenhum dado encontrado no localStorage para 'company'");
    }




    const [pagamentos, setPagamentos] = useState<SmsOrderResponse[]>();


    useEffect(() => {
        const fetchPagamentos = async () => {
            try {
                const response = await pagamentoService.extrato(companyLocal.id)
                const data = await response.data;
                setPagamentos(data);
            } catch (error) {
                console.error('Error to load options:', error);
            }
        };
        fetchPagamentos();
    }, []);


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <> <Breadcrumbs mainTitle='Extrato Compras SMS' parent='CRM' title='Minha loja' />
            <Container fluid={true}>
                <div ref={componentRef}>


                    <Row>
                        <Col>
                            <Card>
                                <CardBody>

                                    <table className="table table-bordered table-striped table-hover table-responsive">
                                        <thead className="table-dark">
                                            <tr>
                                                <th className="text-center">Id</th>
                                                <th className="text-center">Valor</th>
                                                <th className="text-center">Responsável</th>
                                                <th className="text-center">Última atualização</th>
                                                <th className="text-center">Situação</th>
                                                <th className="text-center">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                pagamentos && (
                                                    pagamentos.map(p => (
                                                        <tr key={p.code}>
                                                            <td className="text-center">{p.code}</td>
                                                            <td className="text-end">R$ {p.value.toFixed(2)}</td>
                                                            <td className="text-center">{p.responsible}</td>

                                                            <td className="text-center">{DateService.getDateTime(p.refreshTime)}</td>
                                                            <td className={`text-center ${p.status === 'PAGO' ? 'text-success' : 'text-danger'}`}>
                                                                {p.status}
                                                            </td>
                                                            <td> { p.status !== 'PAGO' &&
                                                            <Link  to={p.paymentLink} target="_blank"
                                                                className="btn btn-success btn-block" 
                                                            >  <FiSave/> Pagar 
                                                            </Link> }

                                                            </td>
                                                        </tr>
                                                    ))
                                                )
                                            }
                                        </tbody>
                                    </table>


                                </CardBody>

                            </Card>

                        </Col>
                    </Row>
                </div>
                <Row>
                <Col sm="12" className="text-center my-3">
                    <Btn attrBtn={{ color: 'primary', className: 'me-2', onClick: handlePrint }}>
                        {'Imprimir'}
                    </Btn>
                    <Link to={`${process.env.PUBLIC_URL}/crm/minhaloja/sms`}>
                        <Btn attrBtn={{ color: 'secondary' }}>{'Voltar'}</Btn>
                    </Link>
                </Col>
                </Row>
            </Container>

        </>
    );
};

export default CrmMinhaLojaExtratoComprasSms; 