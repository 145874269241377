import React, { Fragment } from 'react';
import { H3, H4, P, Image, H6 } from '../../../../../../AbstractElements';
import { Col, Media, Row } from 'reactstrap'; 
import logo1 from '../../../../../../assets/images/other-images/logo-login.png';
import DateService from '../../../../../../Services/utils/date.service';

const InvoiceHeader = (props) => {

  const dados = props.dados;  

  return (
    <>
      <Row className='invo-header'>
        <Col sm='4'>
          <Media>
            <div className='media-left'>
              <Image attrImage={{ className: 'media-object img-60', src: logo1, alt: '' }} />
            </div>
            <Media body className='m-l-20 text-right'>
              <H4 attrH4={{ className: 'media-heading f-w-600' }}>Fanfave</H4>
              <P>
                contato@fanfave.com.br
                <br />
                <span className='digits'>{'(38) 9 8413-4432'}</span>
              </P>
            </Media>
          </Media>
        </Col>
        <Col sm='8'>
          <div className='text-md-end text-xs-center'>
            <H6>
              Pedido  #
              <span className='digits counter'>{dados.reference_id}</span>
            </H6>
            <P>
              Gerado em: <b>{ DateService.getDateTime(dados.created_at)}</b>
            </P>
          </div>
        </Col>
      </Row>
      <hr />
    </>
  );
};
export default InvoiceHeader;
