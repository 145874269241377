import React, { Fragment } from "react";
import { Breadcrumbs, } from "../../../../../AbstractElements";
import { Col, Container, Row, } from "reactstrap";

const CrmMinhaLojaAplicativo = () => {



  return (
    <>
    <Breadcrumbs mainTitle='Aplicativo' parent='CRM' title='Minha loja' />
    <Container fluid={true}>
      <Row>
        <Col>
        <div style={{ fontFamily: 'Arial, sans-serif'  }}>
      {/* Header */}
      <div
        style={{
          backgroundColor: '#0053F0',
          color: 'white',
          padding: '20px',
          borderRadius: '10px',
          textAlign: 'center',
        }}
      >
        <h2 style={{ margin: 0 }}>Luiz Pereira David</h2>
        <p style={{ margin: 0 }}>Informações | Avaliações</p>
      </div>

      {/* Profile Section */}
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: '10px',
          marginTop: '-50px',
          padding: '20px',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <img
            src="https://via.placeholder.com/100"  
            alt="Perfil"
            style={{
              borderRadius: '50%',
              width: '100px',
              height: '100px',
              objectFit: 'cover',
              border: '5px solid white',
            }}
          />
          <h3 style={{ margin: '10px 0' }}>Luiz Pereira David</h3>
        </div>

        {/* Informações */}
        <div style={{ marginTop: '20px' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
            <i
              className="fa fa-building"
              style={{ marginRight: '10px', color: '#0053F0', fontSize: '20px' }}
            ></i>
            <div>
              <p style={{ margin: 0, fontWeight: 'bold' }}>CNPJ</p>
              <p style={{ margin: 0 }}>084.792.776-88</p>
            </div>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
            <i
              className="fa fa-map-marker"
              style={{ marginRight: '10px', color: '#0053F0', fontSize: '20px' }}
            ></i>
            <div>
              <p style={{ margin: 0, fontWeight: 'bold' }}>Endereço</p>
              <p style={{ margin: 0 }}>Avenida Presidente Kennedy</p>
            </div>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
            <i
              className="fa fa-phone"
              style={{ marginRight: '10px', color: '#0053F0', fontSize: '20px' }}
            ></i>
            <div>
              <p style={{ margin: 0, fontWeight: 'bold' }}>Telefone</p>
              <p style={{ margin: 0 }}>Avenida Presidente Kennedy</p>
            </div>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
            <i
              className="fa fa-clock-o"
              style={{ marginRight: '10px', color: '#0053F0', fontSize: '20px' }}
            ></i>
            <div>
              <p style={{ margin: 0, fontWeight: 'bold' }}>Horário de Funcionamento</p>
              <p style={{ margin: 0 }}>Seg-Sex: 10:00 - 22:00</p>
              <p style={{ margin: 0 }}>Sáb-Dom: 12:00 - 23:00</p>
            </div>
          </div>
        </div>
      </div>

      
      <div
        style={{
          marginTop: '20px',
          backgroundColor: 'white',
          borderRadius: '10px',
          padding: '20px',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <h3>Premiações</h3>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
          <img src="https://via.placeholder.com/50" alt="Prêmio" />
          <img src="https://via.placeholder.com/50" alt="Prêmio" />
          <img src="https://via.placeholder.com/50" alt="Prêmio" />
          <p style={{ textAlign: 'center', marginTop: '10px' }}>
            Top Restaurante 2023 | Mais Rápido 2022 | Melhor Atendimento
          </p>
        </div>
      </div>
    </div>
        </Col>
        <Col>
        
        </Col>

      </Row>

   

    </Container>
  </>


    
  );
}; 

export default CrmMinhaLojaAplicativo; 