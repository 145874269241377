import React, { Fragment, useRef } from 'react';
import ItemDescription from './ItemDescription';
import { Breadcrumbs, Btn } from '../../../../../../AbstractElements';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

const InvoiceCompraSms = (props) => {
    const dados = props.dados;
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    if (!dados) {
        return (
            <Container>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <div className="text-center">
                                    <p>Carregando dados...</p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <>
            <Breadcrumbs parent='Compra de SMS' title='Invoice' mainTitle='Minha Loja' />
            <div ref={componentRef}>
                <ItemDescription dados={dados} />
            </div>
            <Row>
                <Col className="text-center my-3">
                    <Btn attrBtn={{ color: 'primary', className: 'me-2', onClick: handlePrint }}>
                        {'Imprimir'}
                    </Btn>
                </Col>
                <Col>
                    <Link to={`${process.env.PUBLIC_URL}/crm/minhaloja/sms`}>
                        <Btn attrBtn={{ color: 'secondary' }}>{'Voltar'}</Btn>
                    </Link>
                </Col>
            </Row>
        </>
    );
};

export default InvoiceCompraSms;