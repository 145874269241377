import axios from "axios";
import authHeader from "../auth-header";
import config from '../../Config/AppConfig'; 
import authHeaderFile from "../auth-header-file";

const API_URL = config.data.API_URL+"/fanfave-api/company";

 class CompanyService    {
  getDadosNps(companyId) {
    return axios
    .get(API_URL+ '/getDadosNps/' + companyId,  { headers: authHeader() });
  }
   

  findByCompanyId(companyId) {    
    return axios
      .get(API_URL+ '/' + companyId,  { headers: authHeader() });
  } 

  getFilter(request, page, size, sort) {    
    return axios
      .post(API_URL+ '/filter?page='+page+'&size='+size, request, { headers: authHeader() });
  } 
 
  getAll() {    
    return axios
      .get(API_URL, { headers: authHeader() });
  } 

  save(request) {    
    return axios
      .post(API_URL, request, { headers: authHeader() });
  } 

  update(request) {    
    return axios
      .put(API_URL+"/"+request.id, request, { headers: authHeader() });
  } 

  delete(request) {  
    
    return axios
      .delete(API_URL+"/"+request.id, { headers: authHeader() });
  } 

  uploadLogo(companyId, request ) {    
    return axios
      .post(API_URL + '/uploadLogo/'+companyId, request, { headers: authHeaderFile() });
  }

  atualizarValorParaPontuar(companyId, request ) {    
    return axios
      .post(API_URL + '/atualizarParametrizacao/'+companyId , request, { headers: authHeader() });
  }

  atualizarDiasDeFuncionamento(companyId, request ) {    
    return axios
      .post(API_URL + '/atualizarDiasDeFuncionamento/'+companyId , request, { headers: authHeader() });
  }


  getSaldoSms(companyId) {    
    return axios
      .get(API_URL+ '/saldoSms/'+companyId,  { headers: authHeader() });
  } 

  getExtratoSms(companyId) {    
    return axios
      .get(API_URL+ '/extratoSms/'+companyId,  { headers: authHeader() });
  } 
  gerarGuiaPagamento(companyId , request) {    
    return axios
      .post(API_URL+ '/gerarGuiaPagamento/'+companyId,  request , { headers: authHeader() });
  } 


  
}  

export default  new CompanyService(); 