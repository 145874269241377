import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Cancel, Collection, Description, EditBookmark, General, Group, MyBookmarks, Save, Sorry, Title, WebUrl } from '../../../../../Constant';

import { Alerts, Badges, Btn, H4, H6 } from '../../../../../AbstractElements';
import { Send } from 'react-feather';
import exportacaoService from '../../../../../Services/administrativo/exportacao.service';
import DataTable from 'react-data-table-component';
import { importacaoColumns } from '../../../../../Data/administrativo/ImportacaoList';
import { exportacaoColumns } from '../../../../../Data/administrativo/ExportacaoList';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const ExportarClienteModal = ({ toggle, modal , requestFilter }) => {
    const { t } = useTranslation();
    const company = JSON.parse(localStorage.getItem('company'));
    const user = JSON.parse(localStorage.getItem('user'));
    const [formato, setFormato] = useState("xlsx");
    const [email, setEmail] = useState("");
    const [erro, setErro] = useState(null);
    const [loading, setLoading] = useState(false);
    const [exportacoes, setExportacoes] = useState([]);



    const filter = {

    }

    useEffect(() => {
        getExportacoes(filter);
        setEmail(null);
        setErro(null);      
    }, [modal])

    const validaEmail = (email) => {
        // Expressão regular para validar o formato do e-mail
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
    // Retorna true se o e-mail corresponder ao padrão, false caso contrário
    return regex.test(email);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let emailValido  = validaEmail(email);

        if (emailValido) {
            setErro('');      
            const request = {
                formato: formato.toUpperCase(),
                tipo: 'EXPORTACAO_CADASTRO_CLIENTE',
                companyId: company.id,
                userId: user.id,
                enviarPara: email,
                filter : requestFilter
            }
            // console.log(request)
            sendExportacao(request);
            getExportacoes();
            setEmail("");
            console.log("Formato:", formato);
            console.log("Email:", email);
        } else {
            setErro("Email inválido");
        }
    };

    const sendExportacao = async (request) => {
        try {
            const response = await exportacaoService.send(request);
            var data = await response.data.content;
            toast.success('Registro enviado com sucesso!');
            getExportacoes();
        } catch (error) {

        }
    };

    const getExportacoes = async () => {
        try {
            const response = await exportacaoService.getAll(filter);
            const exportacoesList = response.data.content;
      
            const transformedItems = exportacoesList.map((exportacao) => ({
              ...exportacao,
              
              situacao: (
                <Badges attrBadge={{ className: 'badge rounded-pill', color: exportacao.situacao === 'Concluído' ? 'success' : exportacao.situacao === 'Erro' ? 'danger' : 'dark', pill: true }}>
                  {exportacao.situacao}
                </Badges>
              ),
             
            }));
      
            setExportacoes(transformedItems);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} className='modal-body' centered={true} size='xl'>
                <ModalHeader toggle={toggle}>Exportando cliente </ModalHeader>
                <ModalBody>
                    <Form className="theme-form mega-form" id="formCountry" name="formCountry" method="POST" onSubmit={handleSubmit}>
                        <Row>
                            <Col md={9}>
                                <FormGroup>
                                    <Label className="col-form-label">  Enviar para o Email</Label>
                                    <Input className="form-control btn-square" type="text" onChange={(e) => setEmail(e.target.value)} value={email} />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <Label className="col-form-label">Clique para enviar</Label>
                                <button type="submit" className="btn btn-success w-100 btn-sm input-group-text"><Send /> {'Enviar'}</button>
                            </Col>
                        </Row>
                        {
                            erro ? <Alerts attrAlert={{ color: 'danger ' }} >
                            <H4 attrH4={{ className: 'alert-heading' }} >{t(Sorry)}</H4>
                            <H6 attrH6={{ className: 'alert-heading' }} >{t(erro)}</H6>
                          </Alerts> : ''
                        }
                    </Form>

                    {
                        loading ? (
                            <p>Loading...</p>
                        ) : exportacoes.length > 0 ? 
                        
                        
                        <DataTable customStyles={{
                            cells: {
                                style: {
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                },
                            },
                        }}
                            showGridlines fixedHeader pagination paginationServer
                            columns={exportacaoColumns} data={exportacoes}
                            highlightOnHover={true} striped={true} responsive={true} /> : 'Nenhum registro encontrado'
                    }
                </ModalBody>
            </Modal>
        </>
    );
};
export default ExportarClienteModal;