import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { H5, H6 } from '../../../../../AbstractElements';
import { PlusCircle } from 'react-feather';
import clienteService from '../../../../../Services/administrativo/cliente.service';
import SweetAlert from 'sweetalert2';

const EnviarMensagemIndividualSmsForm = (props) => {
    const company = JSON.parse(localStorage.getItem('company'));
    const [editorContent, setEditorContent] = useState('');
    const [loading, setLoading] = useState(false);
    const [enviandoSms, setEnviandoSms] = useState(false);

    const cliente = props.cliente;

    console.log(cliente);


    function formatPhoneNumber(phoneNumber) {
        if (!phoneNumber) return '';
        
        // Remove qualquer caractere que não seja número
        const cleaned = phoneNumber.replace(/\D/g, '');
    
        // Verifica o comprimento e formata o número
        if (cleaned.length === 11) {
            // Formato: (XX) 9 XXXX-XXXX
            return cleaned.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})$/, '($1) $2 $3-$4');
        } else if (cleaned.length === 10) {
            // Formato: (XX) XXXX-XXXX
            return cleaned.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
        } else {
            // Retorna o número sem formatação se não estiver no comprimento esperado
            return phoneNumber;
        }
    }

    
    const enviarSms = async () => {
        setEnviandoSms(true);
        try {

            const response = await clienteService.enviaSms(company.id, {
                body: editorContent,
                companyId: company.id,
                  userId: cliente ? cliente.userId : null,
                filter: props.requestFilter
            });
            setEnviandoSms(false);

            if (response.status === 201) {
                SweetAlert.fire({
                    title: 'Sms enviado com sucesso!',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    reverseButtons: true,
                }).then((result) => {
                    setEditorContent('');
                });
            } else {
                SweetAlert.fire({
                    title: 'Erro ao enviar sms',
                    icon: 'danger',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    reverseButtons: true,
                });

            }
        } catch (error) {
            console.error('Erro ao enviar sms:', error);
            alert('Erro ao enviar sms.');

            setEnviandoSms(false);

        }
    };

    return ( enviandoSms ? <div >
        <h1 className="loading-text">
            Aguarde... Sua mensagem está sendo enviada.
        </h1>
        <div className="dots">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div> : loading ? 'Carregando dados' :
        <>
            Enviar SMS para <H5>{cliente ? formatPhoneNumber(cliente.cellphone) : 'Clique naõ encontrado!'}</H5>

            <Row>
                <Col>
                    <label htmlFor="conteudo">Conteúdo</label>
                    <textarea className='form-control'
                        value={editorContent} maxLength={160}
                        onChange={(e) => setEditorContent(e.target.value)} // Corrige para atualizar o estado com o valor do textarea
                        style={{ height: '100px' }} // Define a altura do editor
                    />
                </Col>

            </Row>
            <br />
            <br />
            {
                enviandoSms ?
                    <button type="button" className="btn btn-success disabled w-100 btn-sm input-group-text" >
                        <H6><PlusCircle /> Enviando... </H6>
                    </button> :
                    <button type="button" className="btn btn-success w-100 btn-sm input-group-text" onClick={enviarSms}>
                        <H6><PlusCircle /> Enviar </H6>
                    </button>
            }

        </>
    );
}
export default EnviarMensagemIndividualSmsForm;
