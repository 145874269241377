import React from 'react';
import DataTable from 'react-data-table-component'; 
import AlertError from '../../../../CommonElements/AlertError';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { H5 } from '../../../../AbstractElements';
import SearchNotFoundClass from '../../../Common/CommonWidgets/SearchNotFoundClass';
import { List } from 'react-feather';
import { clientColumns } from '../../../../Data/administrativo/ClientList';

const AdministrativoClientListData = (props) => {
 
  const clientData = props.list; 
  return (
    <Card className={'card-absolute'}>
      <CardHeader className={'bg-primary'}>
        <H5>  <List className='me-2' /> Listando Clientes</H5>
      </CardHeader>
      <CardBody>
        <div className='table-responsive product-table'>{
          props.error ? (
            <AlertError title={'Sorry!'} message={props.error.message} />
          ) :
            props.loading ? (
              <p>Loading...</p>
            ) : clientData.size  == 0 ?
                < SearchNotFoundClass/> :  
            <DataTable noHeader pagination paginationServer columns={clientColumns} data={clientData} highlightOnHover={true} striped={true} responsive={true} />
        }

        </div>
      </CardBody>
    </Card>
  );
};
export default AdministrativoClientListData;
