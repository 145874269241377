import { useEffect, useState } from "react";
import { Btn, H5, } from "../../../../../AbstractElements";
import { Button, Card, CardBody, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, } from "reactstrap";
import productCompanyService from "../../../../../Services/administrativo/productcompany.service";
import { CompanyResponse } from "../../../../../@types/response/company.response";
import { ProductCompanyResponse } from "../../../../../@types/response/product.company.response";
import DateService from "../../../../../Services/utils/date.service";
import { toast } from "react-toastify";
import SweetAlert from 'sweetalert2';
import SearchNotFoundClass from '../../../../Common/CommonWidgets/SearchNotFoundClass';


const CrmMinhaLojaRegrasDeResgate = () => {
  var companyString: string | null = localStorage.getItem('company');
  let companyLocal: CompanyResponse;
  const [products, setProducts] = useState<ProductCompanyResponse[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [initialDate, setInitialDate] = useState("");
  const [endDate, setEndDate] = useState("");



  if (companyString) {
    companyLocal = JSON.parse(companyString);
  } else {
    console.error("Nenhum dado encontrado no localStorage para 'company'");
  }

  const [newProduct, setNewProduct] = useState<ProductCompanyResponse>({
    id: 0,
    description: "",
    companyId: companyLocal!.id,
    initialDate: new Date(),
    endDate: null,
    points: 0,
    status: "ACTIVE",
    value: 0.0
  });

  const [loading, setLoading] = useState(true);

  const fetchCompanyData = async () => {
    try {
      setLoading(true);
      const resp = await productCompanyService.findByCompanyId(companyLocal.id);
      const dados = resp.data;

      const productCompanies: ProductCompanyResponse[] = dados.map((item: any) => ({
        id: item.id,
        description: item.description,
        companyId: item.companyId,
        initialDate: new Date(item.initialDate),
        points: item.points,
        status: item.status,
        endDate: new Date(item.endDate),
        value: item.value
      }));

      setProducts(productCompanies);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setLoading(false);
    }
  };

  // Chama a função dentro do useEffect
  useEffect(() => {
    if (companyLocal) {
      fetchCompanyData();
    }
  }, []);

  const handleCloseModal = () => {
    setShowForm(false);
  };


  const handleClickButtonAdd = () => {
    setNewProduct({
      id: 0,
      description: "",
      companyId: companyLocal!.id,
      initialDate: new Date(),
      endDate: new Date(),
      points: 0,
      status: "ACTIVE",
      value: 0.0
    });
    setShowForm(true);
  }

  const handleSave = () => {
    // Cria uma cópia local do estado com os valores atualizados
    const updatedProduct = {
      ...newProduct,
      initialDate: new Date(initialDate), // Certifique-se de que initialDate é válido
      endDate: new Date(endDate),         // Certifique-se de que endDate é válido
    };
  
    // Atualiza o estado para refletir as alterações
    setNewProduct(updatedProduct); 
  
    // Usa a cópia local atualizada para evitar problemas com o estado assíncrono
    if (updatedProduct.id !== null && updatedProduct.id > 0) {
      productCompanyService.update(updatedProduct)
        .then(() => {
          fetchCompanyData();
          handleCloseModal();
          SweetAlert.fire({
            title: 'Sucesso',
            text: 'Regra atualizada com sucesso!',
            icon: 'success',
          });
        })
        .catch((error) => {
          toast.error('Erro ao salvar regra!');
        });
    } else {
      productCompanyService.save(updatedProduct)
        .then(() => {
          fetchCompanyData();
          handleCloseModal();
          SweetAlert.fire({
            title: 'Sucesso',
            text: 'Regra salva com sucesso!',
            icon: 'success',
          });
        })
        .catch((error) => {
          toast.error('Erro ao salvar regra!');
        });
    }
  };
  
  

  const handleEdit = (product: ProductCompanyResponse) => {
    setInitialDate(DateService.getDateAMD(product.initialDate.toISOString()) ?? '');
    setEndDate(DateService.getDateAMD(product.endDate ? product.endDate.toISOString() : null) ?? '');
    setNewProduct(product); 
    setShowForm(true);  
  };

  const handleDelete = (id: number) => {
    SweetAlert.fire({
      title: 'Tem certeza que deseja remover esta regra?',
      text: 'Uma vez excluído, você não poderá recuperar este registro!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        productCompanyService.delete(id).then((response) => {
          var removeu = response.data;
          if (removeu) {
            fetchCompanyData();

            toast.success('Regra removida com sucesso !');
            handleCloseModal();
          } else {
            toast.error('Regra removida com sucesso !');
          }

        }).catch((error) => {
          toast.error('Erro ao remover regra!!');


        });

      } else {
        SweetAlert.fire(
          'The register went deleted with security!'
        );
      }
    });


  };


  return (loading ? 'Carregando' :
    <>
      <Card>
        <CardBody>
          <Form className="theme-form mega-form" id="formCountry" name="formCountry" method="POST">
            <fieldset>
              <legend>
                <H5>Regras de Resgates</H5>
              </legend>
              <Btn
                attrBtn={{
                  color: "primary",
                  className: "m-r-15",
                  type: "button",
                  onClick: () => handleClickButtonAdd(),
                }}
              >
                {" Adicionar Regra"}
              </Btn>

              {!products || products.length === 0 ? <SearchNotFoundClass message={"Nenhuma regra configurada"} /> :
                <table className="table table-striped mt-3">
                  <thead>
                    <tr>
                      <th>Descrição</th>
                      <th>Data Inicial</th>
                      <th>Data Final</th>
                      <th>Pontos</th>
                      <th>Valor</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((product) => (
                      <tr key={product.id}>
                        <td>{product.description}</td>
                        <td>{product.initialDate ? DateService.getDate(product.initialDate.toISOString().split('T')[0]) : ""}</td> 
                        <td>{product.endDate ? DateService.getDate(product.endDate.toISOString().split('T')[0]) : " - "}</td> 
                        <td>{product.points}</td>
                        <td>{product.value}</td>
                        <td>
                          <span
                            className={`badge ${product.status === "ACTIVE" ? "bg-success" : "bg-danger"
                              }`}
                          >
                            {
                              product.status === "ACTIVE" ? "Ativo" : "Inativo"
                            }
                          </span>
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-primary me-2"
                            type="button"
                            onClick={() => handleEdit(product)}
                          >
                            Editar
                          </button>
                          <button
                            className="btn btn-sm btn-danger" type="button"
                            onClick={() => handleDelete(product.id)}
                          >
                            Remover
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>}
            </fieldset>

          </Form>
        </CardBody>

      </Card>

      {/* Modal para o formulário */}
      <Modal isOpen={showForm} toggle={() => setShowForm(!showForm)}>
        <ModalHeader toggle={() => setShowForm(!showForm)}>Adicionar Regra</ModalHeader>
        <ModalBody>
          <Form>
            <Label for="description">Descrição</Label>
            <Input
              id="description"
              value={newProduct.description}
              onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })}
            />

            <Label for="points" className="mt-2">Pontos (Quantos pontos será necessário para realizar o resgate)</Label>
            <Input
              type="number"
              id="points"
              value={newProduct.points}
              onChange={(e) => setNewProduct({ ...newProduct, points: parseInt(e.target.value) })}
            />

          <Label for="points" className="mt-2">Valor</Label>
            <Input
              type="number"
              id="value"
              value={newProduct.value}
              onChange={(e) => setNewProduct({ ...newProduct, value: parseFloat(e.target.value)})}
            />

            <Label for="initialDate" className="mt-2">Data Inicial</Label>
            <Input
              type="date"
              id="initialDate"
              value={initialDate}
              onChange={(e) => setInitialDate(e.target.value)}
            />

            <Label for="initialDate" className="mt-2">Data Final</Label>
            <Input
              type="date"
              id="endDate"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />


            <Label for="status" className="mt-2">Status</Label>
            <Input
              type="select"
              id="status"
              value={newProduct.status}
              onChange={(e) => setNewProduct({ ...newProduct, status: e.target.value })}
            >
              <option value="ACTIVE">Ativo</option>
              <option value="INACTIVE">Inativo</option>
            </Input>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            Salvar
          </Button>
          <Button color="secondary" onClick={() => setShowForm(false)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CrmMinhaLojaRegrasDeResgate; 