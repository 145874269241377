import React, { Fragment } from "react";
import { Breadcrumbs } from "../../../../AbstractElements";
import { Container, Row } from "reactstrap";
import LocalizarCliente from "./LocalizarCliente";
import { CompanyResponse } from "../../../../@types/response/company.response";
 
const getCompany = (): CompanyResponse | null => {
  const companyString = localStorage.getItem("company");
  return companyString ? JSON.parse(companyString) : null;
};

const CrmPontuarResgatar = () => {
  
  const company = getCompany();

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle={company?.name || "Empresa não definida"}
        parent="CRM"
        title="Dashboard"
      />
      <Container fluid>
        <Row className="size-column">
          <LocalizarCliente />
        </Row>
      </Container>
    </Fragment>
  );
};

export default CrmPontuarResgatar;
