import React, { useEffect, useState } from 'react';
import clienteService from '../../../../../Services/administrativo/cliente.service';
import DataTable from 'react-data-table-component';
import { extratoPontuacaoClienteColumns } from '../../../../../Data/crm/ExtratoPontuacaoClienteList';
import { H3 } from '../../../../../AbstractElements';
import SearchNotFoundClass from '../../../../Common/CommonWidgets/SearchNotFoundClass';

const ExtratoPontuacaoClienteList = ({ cliente, loading, dados }) => {
 

    return (loading ? 'Carregando' : dados.length === 0 ? <SearchNotFoundClass message={'Não foi encontrado nenhum registro de pontuação para esse cliente! '} /> :
        <><H3>Pontuação</H3>
            <DataTable noHeader columns={extratoPontuacaoClienteColumns} data={dados} highlightOnHover={true} striped={true} responsive={true} />

        </>
    );
};

export default ExtratoPontuacaoClienteList;
