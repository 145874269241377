import React  from 'react';
import Routers from './Route';
import AnimationThemeProvider from './_helper/AnimationTheme/AnimationThemeProvider';
import CustomizerProvider from './_helper/Customizer/CustomizerProvider'; 
import { AuthProvider } from './_helper/Auth/AuthProvider';

const App = () => (



  <div className='App'>
    <AuthProvider>
      <CustomizerProvider>
        <AnimationThemeProvider>
          <Routers />
        </AnimationThemeProvider>
      </CustomizerProvider>
    </AuthProvider>
  </div>
);

export default App;
