import { P } from '../../../../../../AbstractElements';
import paypal from '../../../../../../assets/images/other-images/paypal.png';
import React, { Fragment } from 'react';
import { Card, CardBody, Col, Container, Form, Input, Row } from 'reactstrap';

const PrintInvoice = (props) => {
  const dados = props.dados;
  if (!dados) {
    return (
      <Container>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className="text-center">
                  <p>Carregando dados...</p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Fragment>
      <Row className="mt-3">
        <Col md="8">
          <div>
            <P attrPara={{ className: 'legal' }}><strong>Obrigado por fazer negócios conosco!</strong> O pagamento é esperado dentro de 31 dias; processe esta fatura dentro desse prazo.</P>
          </div>
        </Col>
        <Col md="4">
          <Form className="float-end invo-pal">

            <a
              href={dados.links[1].href}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary btn-lg"
              style={{ textDecoration: 'none', padding: '10px 20px', fontSize: '1.25rem' }}
            >
              Efetuar pagamento
            </a>

          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};
export default PrintInvoice;