import React, { useEffect, useState } from 'react';
import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'; 
import ExtratoPontuacaoClienteList from './ExtratoPontuacaoClienteList';
import ExtratoResgateClienteList from './ExtratoResgateClienteList';
import clienteService from '../../../../../Services/administrativo/cliente.service';

const ExtratosCliente = ({ cliente }) => {
    const [extratoResgate, setExtratoResgate] = useState([]);
    const [BasicLineTab, setBasicLineTab] = useState('1');
    const [loading, setLoading] = useState(true);
    const [extratoPontuacao, setExtratoPontuacao] =  useState([]);

    useEffect( () => {
        const fetchData = async () => {
            try {
                await getExtratoResgateClienteList();
                await getExtratoPontuacaoClienteList();
                setLoading(false);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchData(); // Chamar a função assíncrona
    },[]);
    
    const getExtratoResgateClienteList = async () => { 
        try {
            const response = await clienteService.getResgates(cliente.id);
            const data = await response.data;
            setExtratoResgate(data); 
        } catch (error) {
            console.error(error);
         }   finally {
            setLoading(false);
        }
    }

    const getExtratoPontuacaoClienteList = async () => {
        setLoading(true);
        try {
            const response = await clienteService.getPontuacoes(cliente.id);
            const data = await response.data;
            setExtratoPontuacao(data);
           
        } catch (error) {
         console.error(error);
        }
    }


    return (
        <><Card>
            <CardBody>
                <Nav className='border-tab d-flex' tabs>
                    <NavItem className="flex-grow-1">
                        <NavLink href='#' className={'w-100' + BasicLineTab === '1' ? ' active btn btn-succes' : ''} onClick={() => setBasicLineTab('1')}>
                            <i className="icofont icofont-envelope"></i>
                            {'Pontuações'}
                        </NavLink>
                    </NavItem>

                    <NavItem className="flex-grow-1">
                        <NavLink href='#' className={'w-100' + BasicLineTab === '2' ? ' active' : ''} onClick={() => setBasicLineTab('2')}>
                            <i className="icofont icofont-envelope"></i>
                            {'Resgates'}
                        </NavLink>
                    </NavItem>

                </Nav>
                <TabContent activeTab={BasicLineTab}>
                    <TabPane className='fade show' tabId='1'>
                        <ExtratoPontuacaoClienteList cliente={cliente} dados={ extratoPontuacao}  loading={loading} />
                         
                    </TabPane>
                    <TabPane tabId='2'>
                        <ExtratoResgateClienteList cliente={cliente}  dados={extratoResgate }  loading={loading} />
                    </TabPane>
                  
                </TabContent></CardBody>
        </Card>
        </>
    );
};

export default ExtratosCliente;
