import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Row, Col, FormGroup, Label, Input, } from 'reactstrap';
import { H5, Image } from '../../../../AbstractElements';
import { Select } from "../../../../Constant";
import MaskedInput from 'react-text-mask';
import dateService from '../../../../Services/utils/date.service';
import cepService from '../../../../Services/utils/cep.service';
import cityService from '../../../../Services/administrativo/city.service';
import stateService from '../../../../Services/administrativo/state.service';
import downloadService from '../../../../Services/utils/download.service'; 



const PhoneMask = ({ inputRef, ...otherProps }) => (
  <MaskedInput
    {...otherProps}
    inputRef={inputRef} 
    mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    placeholderChar={'\u2000'}
    showMask
  />
);


const CellPhoneMask = ({ inputRef, ...otherProps }) => (
  <MaskedInput
    {...otherProps}
    inputRef={inputRef} 
    mask={['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    placeholderChar={'\u2000'}
    showMask
  />
);


const CepMask = ({ inputRef, ...otherProps }) => (
  <MaskedInput
    {...otherProps}
    inputRef={inputRef} 
    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
    placeholderChar={'\u2000'}
    showMask
  />
);



const UserPersonForm = (props) => { 
  const picture = props.picture ? downloadService.getBase64Picture(props.picture) : ''  ;
  const requestPerson =  props.requestPerson ;

  const [rg, setRg] = useState(props.data.rg);
  const [name, setName] = useState(props.data.name);
  const [dateOfBirth, setDateOfBirth] = useState(dateService.getDate(props.data.dateOfBirth));
  const [phone, setPhone] = useState(props.data.phone);
  const [cellphone, setCellphone] = useState(props.data.cellphone);
  const [mail, setMail] = useState(props.data.mail);
  const [zipCode, setZipCode] = useState(props.data.zipCode);
  const [address, setAddress] = useState(props.data.address);
  const [number, setNumber] = useState(props.data.number); 
   

  const [complement, setComplement] = useState(props.data.complement);
  const [district, setDistrict] = useState(props.data.district);
  const [stateId, setStateId] = useState(props.data.city ? props.data.city.state.id : null);
  const [cityId, setCityId] = useState(props.data.city ? props.data.city.id : null);
  const [listOfState, setListOfState] = useState([]);
  const [listOfCity, setListOfCity] = useState([]);
  const [validCep, setValidCep] = useState(true); 
  const fileInputRef = useRef(null);


  const somenteLeitura = props.somenteLeitura;
  const option = props.option;

  
   

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (event.target.files.length === 0) return;

    try {
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
 
       

 
      //setUploaded(true);
    } catch (error) {
      console.error('Erro ao enviar o arquivo:', error);
    }
  };


  const updateCityList = async (uf, codeIbge) => {
    try {
      if (uf === '') {
        setListOfCity([]);
      } else {
        if (uf == null) setListOfCity([]);
        else {
          const response = await cityService.getAllByUf(uf);
          const data = await response.data;
          setListOfCity(data);
          setCityId(data.find(city => city.code === codeIbge).id);
          requestPerson.cityId = data.data.find(city => city.code === codeIbge).id;
          requestPerson.city = data.data.find(city => city.code === codeIbge).id;
        }
      }
    } catch (error) {
      console.error('Error to load options:', error);
    }
  }


  const updateCep = async (cep) => {
    try {

      cep = cep.trim();
      if (cep === null || cep === '' || cep.replace('.', '').replace('-', '').length !== 8) {
        return null;
      }

      await cepService.getCep(cep).then((response) => {
        const data = response.data;
        if (data.erro) {
          setValidCep(false);
          setAddress('');
          setDistrict('');
          setNumber('');
          setComplement('');
          setStateId(null);
          setCityId(null);
          setListOfCity([]);
          requestPerson.address = address;
          requestPerson.district = district;
          requestPerson.number = number;
          requestPerson.complement = complement;
          requestPerson.stateId = stateId;
          requestPerson.cityId = cityId;
          requestPerson.city = cityId;


        } else {
          setValidCep(true);
          var newStateId = listOfState.find(state => state.acronym === data.uf).id;
          if (newStateId === stateId) {
            setCityId(listOfCity.find(city => city.code === data.ibge).id);
          } else {
            setStateId(newStateId);
            updateCityList(data.uf, data.ibge);
          }


          setAddress(data.logradouro);
          setDistrict(data.bairro);
          setNumber('');
          setComplement('');

          requestPerson.address = data.logradouro;
          requestPerson.district = data.bairro;
          requestPerson.number = number;
          requestPerson.complement = complement;

          requestPerson.zipCode = cep;

        }


      }

      );



    } catch (error) {
      console.error('Error to load options:', error);
    }
  }

  useEffect(() => {
    const fetListOfState = async () => {
      try {
        const response = await stateService.getAll();
        const data = await response.data;
        setListOfState(data);
      } catch (error) {
        console.error('Error to load options:', error);
      }
    };
    fetListOfState();
  }, []);


  useEffect(() => {
    const fetListOfCity = async () => {
      try {
        if (stateId == null) {
          setListOfCity([]);
        } else {
          const response = await cityService.getAll(stateId);
          const data = await response.data;
          setListOfCity(data);
        }
      } catch (error) {
        console.error('Error to load options:', error);
      }
    };
    fetListOfCity();
  }, []);


  const handleIconClick = () => {
    fileInputRef.current.click();
  };


  return (
    <Fragment>
      <Col sm="12">
        {props.loading ? (
          <p>Loading...</p>
        ) : props.error ? (
          <p>Error: {props.error.message}</p>
        ) : ''
        }






        <Row>
          <Col md={4}>
            <div className='profile-title'>
              <div className='media'>
                <Image attrImage={{ className: 'img-70 img-fluid m-r-5 rounded-circle b-r-10 m-0 step1', alt: '', src: `${picture}` }} />
                <i className='icofont icofont-pencil-alt-5' onClick={handleIconClick}>
                  <input type="file" style={{ display: 'none' }} ref={fileInputRef} onChange={(e) => handleSubmit(e)} accept="image/*" />
                </i>
              </div>
            </div>
          </Col>
          <Col md={8}>
            <fieldset>
              <legend><H5>{option} Dados Pessoais</H5></legend>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label className="col-form-label"> Name</Label>
                    <Input className={'form-control'}
                      style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
                      disabled={somenteLeitura} type="text"
                      onChange={(e) => { setName(e.target.value); requestPerson.name = name }} value={name} />
                  </FormGroup>
                </Col>

                <Col md={3}><FormGroup>
                  <Label className="col-form-label">Rg</Label>
                  <Input className={'form-control '}
                    style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
                    readOnly={somenteLeitura} type="text" onChange={(e) => { setRg(e.target.value); requestPerson.rg = rg; }} value={rg} />
                </FormGroup>
                </Col>
                <Col md={3}><FormGroup>
                  <Label className="col-form-label">Date of Birth</Label>
                  <Input className="form-control"
                    style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
                    type="date" readOnly={somenteLeitura} placeholder="dd/mm/aaaa" onChange={(e) => { setDateOfBirth(e.target.value); requestPerson.dateOfBirth = dateOfBirth; }} value={dateOfBirth} />
                </FormGroup>
                </Col>
              </Row>
            </fieldset>
          </Col>
        </Row>




        <H5>Address</H5>
        <Row><Col md={3}><FormGroup>
          <Label className="col-form-label">ZipCode</Label>
          <Input className={'form-control'}
            style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
            tag={CepMask} readOnly={somenteLeitura} type="text" onChange={(e) => { setZipCode(e.target.value); requestPerson.zipCode = zipCode; updateCep(e.target.value); }} value={zipCode} />
        </FormGroup>
        </Col><Col md={6}><FormGroup>
          <Label className="col-form-label">Address</Label>
          <Input className={'form-control '}
            style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
            readOnly={somenteLeitura} type="text" onChange={(e) => { setAddress(e.target.value); requestPerson.address = address; }} value={address} />
        </FormGroup>
          </Col><Col md={3}><FormGroup>
            <Label className="col-form-label">Number</Label>
            <Input className={'form-control '}
              style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
              type="text" readOnly={somenteLeitura} onChange={(e) => { setNumber(e.target.value); requestPerson.number = number; }} value={number} />
          </FormGroup>
          </Col></Row><Row><Col md={3}><FormGroup>
            <Label className="col-form-label">Complement</Label>
            <Input className={'form-control '}
              style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
              type="text" readOnly={somenteLeitura} onChange={(e) => { setComplement(e.target.value); requestPerson.complement = complement; }} value={complement} />
          </FormGroup>
          </Col>
          <Col md={3}><FormGroup>
            <Label className="col-form-label">District</Label>
            <Input className={'form-control '}
              style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
              type="text" readOnly={validCep || somenteLeitura} onChange={(e) => { setDistrict(e.target.value); requestPerson.district = district; }} value={district} />
          </FormGroup>
          </Col><Col md={3}>

            <FormGroup>
              <Label className="col-form-label">State:</Label>
              <select className='form-control'
                style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
                size='1' value={stateId} onChange={(e) => { setStateId(e.target.value.id); updateCityList(e.target.value); requestPerson.stateId = stateId; }}>
                <option value='' >{Select}</option>
                {listOfState.map((state) => (
                  <option value={state.id} key={state.id} selected={state.id === stateId} >{state.name}</option>
                ))}
              </select>
            </FormGroup>
          </Col><Col md={3}>
            <FormGroup>
              <Label className="col-form-label">City:</Label>
              <select className='form-control'
                style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
                size='1' value={cityId} onChange={(e) => { setCityId(e.target.value); requestPerson.stateId = stateId; }}>
                <option >{Select}</option>
                {listOfCity.map((city) => (
                  <option value={city.id} key={city.id} selected={city.id === city} >{city.name}</option>
                ))}
              </select>
            </FormGroup>
          </Col>
        </Row>

        <H5>Contatos</H5>
        <Row><Col md={3}><FormGroup>
          <Label className="col-form-label">Phone</Label>
          <Input tag={PhoneMask}
            style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
            className={'form-control '} type="text" placeholder="Phone" onChange={(e) => { setPhone(e.target.value); requestPerson.phone = phone; }} value={phone} />
        </FormGroup>
        </Col><Col md={3}><FormGroup>
          <Label className="col-form-label">Cellphone</Label>
          <Input tag={CellPhoneMask}
            style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
            className={'form-control '} type="text" placeholder="Cellphone" onChange={(e) => { setCellphone(e.target.value); requestPerson.cellphone = cellphone; }} value={cellphone} />
        </FormGroup>
          </Col><Col md={6}><FormGroup>
            <Label className="col-form-label">Mail</Label>
            <Input className={'form-control '}
              style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
              type="text" placeholder="Mail" onChange={(e) => { setMail(e.target.value); requestPerson.mail = mail; }} value={mail} />
          </FormGroup>
          </Col>
        </Row>

      </Col>
    </Fragment>
  );
};
export default UserPersonForm;
