import React, { useEffect, useState } from 'react';
import {  Form,   Input,   Label, Modal, ModalBody, ModalHeader,  } from 'reactstrap';
import { Btn, H3, H5, H6 } from '../../../../../AbstractElements';
import clienteService from '../../../../../Services/administrativo/cliente.service';
import SweetAlert from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router';

const PontuarModal = ({ toggle, modal, setModalPontuar, client }) => {
    const companyLocal = JSON.parse(localStorage.getItem('company')); 

    const  valorParaPontuar  = companyLocal.valorParaPontuar ? companyLocal.valorParaPontuar : 0;  

    const [value, setValue] = useState('0');
    const [generatedPoints, setGeneratedPoints] = useState('0');
    const [restPoints, setRestPoints] = useState('0');
    const userId = JSON.parse(localStorage.getItem('userId'));
    const navigate = useNavigate();
    const location = useLocation();

    const updateGeneratedPoints = (value) => {
        var pointed = Math.floor(value / valorParaPontuar);
        var rest = value % valorParaPontuar;
        let valorDecimalResto = rest / valorParaPontuar;
        setGeneratedPoints(pointed);
        setRestPoints(valorDecimalResto);
    }

    useEffect(() => {
        setValue('');
        setGeneratedPoints('0');
    }, []);

    const salvarPontuacao = () => {
        var request = {
            clientId: client.client.id,
            companyId: client.company.id,
            points: generatedPoints,
            rest: restPoints,
            value: value,
            userId: userId,
        };
        return clienteService.pontuar(request)
            .then((response) => {
                SweetAlert.fire({ title: 'Sucesso', text: 'Pontuação feita com sucesso', icon: 'success', });
                setModalPontuar(false);
                navigate(location.pathname, { replace: true }); 
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} className='modal-body' centered={true} size='lg'>
                <ModalHeader toggle={toggle}>Registrar Pontuação</ModalHeader>
                <ModalBody>
                    <Form className='theme-form crypto-form'>
                        {/* Valor da compra */}
                        <div className='mb-3'>
    <Label className='form-label f-light' htmlFor='money'>
        <H5>{'Informe o valor total da compra feita pelo cliente'}</H5>
    </Label>
    <div className='position-relative'>
        <select className='form-select crypto-select warning' style={{ height: 'calc(3.5rem + 2px)', marginLeft: '10px', fontSize: '1.5rem' }}>
            <option>{'R$'}</option>
        </select>
        <Input 
            id='money'
            className='form-control form-control-lg highlighted-input' 
            type='number' 
            onChange={(e) => { setValue(e.target.value); updateGeneratedPoints(e.target.value); }} 
            value={value} 
            style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold', padding: '20px' }} 
        />
      
    </div>
</div>


                        {/* Pontuação gerada com destaque */}
                        <div className='mb-3'>
                            <Label className='form-label f-light' for='coin'>
                            <H5>{'Pontuação gerada'}</H5>
                            </Label>
                            <div className='position-relative text-center'>
                                <h1 className="generated-points-display">
                                    {generatedPoints}
                                </h1>
                            </div>
                        </div>

                        <Btn 
                            value="Pontuar" 
                            attrBtn={{ 
                                type: 'button', 
                                color: 'success', 
                                className: 'btn-hover-effect w-100', 
                                name: 'clickedButton', 
                                onClick: salvarPontuacao 
                            }}>
                            Salvar
                        </Btn>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    );
};

export default PontuarModal;
