import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody, CardHeader, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Table } from 'reactstrap';
import { Image, H5, P } from '../../../../AbstractElements';
import crmDashboardService from '../../../../Services/crm/crm.dashboard.service';
import downloadService from '../../../../Services/utils/download.service';
import CustomizerContext from '../../../../_helper/Customizer';
import LoadingView from '../../../../CommonElements/Loading';
import DateService from '../../../../Services/utils/date.service';


const RecentSales = () => {
  const company = JSON.parse(localStorage.getItem('company'));
  const userId = JSON.parse(localStorage.getItem('userId'));
  const [recentSalesData, setRecentSalesData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const { layoutURL } = useContext(CustomizerContext);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const history = useNavigate();


  const updateRecentSalesData = async (period) => {
    setSelectedValue(period);
    setLoading(true);
    try {
      if (period === 'Ontem') period = 'YERSTERDAY'
      else period = 'TODAY';
      const response = await crmDashboardService.recentRegisters(company.id, userId, period);
      const data = await response.data;
      setRecentSalesData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error to load options:', error);
      setLoading(false);
    }
  }

  useEffect(() => {
    const fetListRecentSalesData = async () => {
      updateRecentSalesData('Hoje');
    };
    fetListRecentSalesData();

  }, [])

  
  const irTelaCliente = (clienteUserId) => {
    localStorage.setItem('clienteUserId', clienteUserId);  
    history('/crm/clientes/dashboard');
  }

  return (
    <div className='appointment'>
      <Card>
        <CardHeader className='card-no-border'>
          <div className='header-top'>
            <H5 attrH5={{ className: 'm-0' }}>Resgates/Pontuações recentes</H5>
            <div className='card-header-right-icon'>
              <Dropdown icon={false} isOpen={dropdownOpen} toggle={toggle} caret >
                <DropdownToggle  {...{ caret: true }}>
                  {selectedValue ? selectedValue : 'Hoje'}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem key={0} onClick={() => updateRecentSalesData('Hoje')}>Hoje</DropdownItem>
                  <DropdownItem key={1} onClick={() => updateRecentSalesData('Ontem')}>Ontem</DropdownItem>
                </DropdownMenu>
              </Dropdown >
            </div>
          </div>
        </CardHeader>
        <CardBody className='pt-0'>
          <div className='appointment-table'>
            {
              loading ? <LoadingView/> : 
              recentSalesData && recentSalesData.length === 0 ? 'Nenhum registro encontrado' : 
            <Table borderless responsive>
              <tbody>
                {recentSalesData && recentSalesData.map((item, i) => (
                  <tr key={i}  >
                    <td>
                      <Image attrImage={{ className: 'img-fluid img-40 rounded-circle', src: downloadService.getImagem(item.picture), alt: 'user' }} />
                    </td>
                    <td className='img-content-box'>
                      <a  href="#"  onClick={(e) => {
                            e.preventDefault();  
                            irTelaCliente(item.userId);
                          }}>
                        {item.name}                      
                        <span className='f-light'>{item.description}</span>
                      </a>

                     
            
                    </td>
                    <td>
                      <span className='f-light'>{ DateService.getTime(item.operationDate)}</span>
                    </td>
                    <td className='text-end'>
                      <P attrPara={{ className: (item.operation === 'PUNCTUATE' ? 'm-0 font-success' : 'm-0 font-danger') }}>  <H5>{item.operation === 'PUNCTUATE' ? '+' : '-'}{Math.floor(item.value)}</H5></P>
                    </td>
                    
                  </tr>
                ))}
              </tbody>
            </Table> }
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default RecentSales;
