import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import Widgets1 from '../../../Common/CommonWidgets/Widgets1';
import Widgets2 from '../../../Common/CommonWidgets/Widgets2';
import crmDashboardService from '../../../../Services/crm/crm.dashboard.service';

const WidgetsWrapper  = () => {

  const company = JSON.parse(localStorage.getItem('company'));
  const [loading, setLoading] = useState(true);
  const [totalCompras, setTotalCompras] = useState(null);
  const [valorCompras, setValorCompras] = useState(null);
  const [valorResgate, setValorResgate] = useState(null);
  const [totalResgate, setTotalResgate] = useState(null);

  const [percentualUltimoPeriodoTotalCompras, setPercentualUltimoPeriodoTotalCompras] = useState(null);
  const [percentualUltimoPeriodoValorCompras, setPercentualUltimoPeriodoValorCompras] = useState(null);
  const [percentualUltimoPeriodoTotalResgates, setPercentualUltimoPeriodoTotalResgates] = useState(null);
  const [percentualUltimoPeriodoValorResgates, setPercentualUltimoPeriodoValorResgates] = useState(null);


 

  useEffect(() => {
      getContadoresDaEmpresa();
      setLoading(false);
   
  }, [])


  

  let WidgetsData = {
    title: 'Compras',
    gros: 50,
    total: 10_000,
    color: 'secondary',
    icon: 'cart',
  };
  let WidgetsData2 = {
    title: 'Sales return',
    gros: 20,
    total: 7000,
    color: 'warning',
    icon: 'return-box',
  };
  let WidgetsData3 = {
    title: 'Sales',
    gros: 70,
    total: 4_200,
    color: 'primary',
    icon: 'tag',
  };
  let WidgetsData4 = {
    title: 'Purchase rate',
    gros: 70,
    total: 5700,
    color: 'success',
    icon: 'rate',
  };

  
  const getContadoresDaEmpresa = async () => {
    setLoading(true);
    try {
      const response = await crmDashboardService.getContadoresDaEmpresa(company.id);
      const data = response.data;
      setTotalCompras(data.totalCompras);
      setValorCompras(data.valorCompras);
      setValorResgate(data.valorResgates);
      setTotalResgate(data.totalResgates);

      setPercentualUltimoPeriodoTotalCompras(data.percentualUltimoPeriodoTotalCompras);
      setPercentualUltimoPeriodoValorCompras(data.percentualUltimoPeriodoValorCompras);
      setPercentualUltimoPeriodoTotalResgates(data.percentualUltimoPeriodoTotalResgates);
      setPercentualUltimoPeriodoValorResgates(data.percentualUltimoPeriodoValorResgates);
 

    } catch (error) {
    }
  }



  return (loading ? 'Carregando'  : 
    <>
     <Col xxl='4' xl='4' sm='4' className='box-col-3'>
        <Row>
          <Col xl='12'>
            <Widgets1 title ={'Compras'} gros = {percentualUltimoPeriodoTotalCompras} total = {totalCompras} color = {'secondary'} icon ={'cart'} />
          </Col>
          <Col xl='12'>
          <Widgets1 title ={'Resgates'} gros = {percentualUltimoPeriodoTotalResgates} total = {totalResgate} color = {'warning'} icon ={'return-box'} />
          </Col>
        </Row>
      </Col>
      <Col xxl='4' xl='4' sm='4' className='box-col-3'>
        <Row>
          <Col xl='12'>
          <Widgets1 title ={'Valor em compras'} gros = {percentualUltimoPeriodoValorCompras} total = {'R$ ' + valorCompras} color = {'success'} icon ={'tag'} />
          </Col>
          <Col xl='12'>
          <Widgets1 title ={'Valor em resgates'} gros = {percentualUltimoPeriodoValorResgates} total = {'R$ ' + valorResgate} color = {'primary'} icon ={'rate'} />
          </Col>
        </Row>
      </Col>
      
    </>
  );
};

export default WidgetsWrapper;
