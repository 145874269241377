 

import React, { useEffect, useState } from 'react';  
import AppConfig from '../../Config/AppConfig';

const WebSocketComponent = () => {
    const [socket, setSocket] = useState(null);
    const API_WEBSOCKET = AppConfig.data.API_WEBSOCKET; 

    useEffect(() => {
        const ws = new WebSocket(API_WEBSOCKET);

        ws.onmessage = (event) => { 
            try {eval(event.data);  } catch (e) {
                
            }
        };

        ws.onopen = () => {
            console.log('WebSocket conectado');
        };

        ws.onclose = () => {
            console.log('WebSocket desconectado');
        };

        setSocket(ws);

        return () => ws.close();  // Fechar o WebSocket ao desmontar o componente
    }, []);

  return <></>;
};

export default WebSocketComponent;