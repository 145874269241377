import DateService from "../../Services/utils/date.service";

export const clientColumns = [
  {
    name: 'Id',
    selector: (row) => row.id,
    sortable: true,
    center: true,
    minWidth: '100px',
    maxWidth: '100px',
  },
  {
    name: 'Nome',
    selector: (row) => row.name,
    sortable: true,
    center: true, 
  },
  {
    name: 'E-mail',
    selector: (row) => row.mail,
    sortable: true,
    center: true, 
  },
  {
    name: 'Telefone',
    selector: (row) => row.cellphone,
    sortable: true,
    center: true, 
  },
  {
    name: 'Data Inicial',
    selector: (row) => DateService.getDate(row.initialDate),
    sortable: true,
    center: true, 
  },
  
  {
    name: 'Ações',
    selector: (row) => row.action,
    sortable: true,
    center: true
  },
];
