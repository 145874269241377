import React, { useState } from "react";
import { Card, CardBody, CardFooter, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import HeaderCard from "../../../../../Common/Component/HeaderCard";
import { Breadcrumbs, H4 } from "../../../../../../AbstractElements";
import { FiSave, FiXCircle } from "react-icons/fi";
import { useNavigate } from "react-router";
import SweetAlert from 'sweetalert2';
import companyService from "../../../../../../Services/administrativo/company.service";
import SaldoSms from "../SaldoSms";
import ExtratoPagamentoSms from "../ExtratoPagamentoSms";
import InvoiceCompraSms from "./invoce";

const CrmMinhaLojaComprarSms = () => {
    var companyLocal = JSON.parse(localStorage.getItem('company'));
    var userLocal = JSON.parse(localStorage.getItem('user'));
    const [valor, setValor] = useState(null);
    const [nome, setNome] = useState(companyLocal.name);
    const [cpfCnpj, setCpfCnpj] = useState(companyLocal.cpfCnpj);
    const [email, setEmail] = useState(companyLocal.email);
    const [telefone, setTelefone] = useState(companyLocal.telefone);
    const history = useNavigate();
    const [gerou, setGerou] = useState(false);
    const [gerandoGuia, setGerandoGuia] = useState(false);
    const [link, setLink] = useState(null);
    const [errors, setErrors] = useState({});
    const [dadosPagSeguro, setDadosPagSeguro] = useState(null);


    const validateFields = () => {
        const newErrors = {};
        if (!valor) newErrors.valor = "O valor é obrigatório.";
        if (!nome) newErrors.nome = "O nome é obrigatório.";
        if (!cpfCnpj) newErrors.cpfCnpj = "O CPF/CNPJ é obrigatório.";
        if (!telefone) newErrors.telefone = "O telefone é obrigatório.";
        if (!email || !/\S+@\S+\.\S+/.test(email)) newErrors.email = "O email é inválido.";
        setErrors(newErrors);

        console.log(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    let request = {
        companyId: companyLocal.id,
        nome: nome,
        cpfCnpj: cpfCnpj,
        email: email,
        telefone: telefone,
        valor: +valor,
        userId: userLocal.id
    }
    const gerarGuia = async () => {
        setGerandoGuia(true);
        if (!validateFields()) {

            SweetAlert.fire({
                title: "Erro",
                text: "Por favor, preencha todos os campos corretamente.",
                icon: "error",
            });
            setGerandoGuia(false);
            return;
        }

         

        companyService.gerarGuiaPagamento(companyLocal.id, request).then((response) => {
            if (response.status === 200) {
                setDadosPagSeguro(response.data);
                var link = response.data.links[1].href;
                setLink(link); 
                setGerou(true);
                setGerandoGuia(false);
            }


        }).catch((error) => {
            SweetAlert.fire({ title: 'Desculpe', text: 'Ocorreu um erro ao salvar o cliente', icon: 'danger', });
            setGerandoGuia(false);
        });
    };


    const cancelar = () => {

        if (gerou) history(-2);
        else history(-1);
    };

    return (gerandoGuia ? <div className="loading-container">
        <h1 className="loading-text">
            Aguardando... Sua guia está sendo processada
        </h1>
        <div className="dots">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div> : gerou ?
        <InvoiceCompraSms dados={dadosPagSeguro} link = {link} /> :
        <> <Breadcrumbs mainTitle='Comprar SMS' parent='CRM' title='Minha loja' />

            <Container fluid={true}>
                  
                <Row>
                    <Col>
                        <Card className='checkout'>
                          
                            <CardBody>
                                <Form className='theme-form crypto-form'>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="valor">Valor</Label>
                                                <Input
                                                    type="number"
                                                    id="valor"
                                                    name="valor"
                                                    value={valor}
                                                    onChange={(e) => setValor(e.target.value)} 
                                                    className={`form-control-lg ${errors.valor ? "is-invalid" : ""}`}
                                                    placeholder="Digite o valor"
                                                />
                                                 {errors.valor && <div className="invalid-feedback">{errors.valor}</div>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={8}>
                                            <FormGroup>
                                                <Label for="nome">Nome</Label>
                                                <Input
                                                    type="text"
                                                    id="nome"
                                                    name="nome"
                                                    value={nome}
                                                    onChange={(e) => setNome(e.target.value)}
                                                    className={`form-control-lg ${errors.nome ? "is-invalid" : ""}`}
                                                    placeholder="Digite o nome completo"
                                                />
                                                {errors.nome && <div className="invalid-feedback">{errors.nome}</div>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="telefone">Telefone</Label>
                                                <Input
                                                    type="text"
                                                    id="telefone"
                                                    name="telefone"
                                                    value={telefone}
                                                    className={`form-control-lg ${errors.telefone ? "is-invalid" : ""}`}
                                                    onChange={(e) => setTelefone(e.target.value)}
                                                    placeholder="Digite o telefone"
                                                />
                                                {errors.telefone && <div className="invalid-feedback">{errors.telefone}</div>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="email">Email</Label>
                                                <Input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    value={email}
                                                    className={`form-control-lg ${errors.email ? "is-invalid" : ""}`}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder="Digite o email"
                                                />
                                                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </Form>

                            </CardBody> <CardFooter>
                                <Row>
                                    <Col md={6} className="text-center">
                                        <button
                                            type="button"
                                            className="btn btn-danger btn-lg w-75"
                                            onClick={cancelar}
                                        >
                                            <FiXCircle style={{ marginRight: '5px' }} />
                                            Cancelar
                                        </button>
                                    </Col>
                                    {!gerou && (
                                        <Col md={6} className="text-center">
                                            <button
                                                type="button"
                                                className="btn btn-success btn-lg w-75"
                                                onClick={gerarGuia}
                                            >
                                                <FiSave style={{ marginRight: '5px' }} />
                                                Gerar Guia
                                            </button>
                                        </Col>
                                    )}
                                </Row>

                            </CardFooter>
                        </Card>
                    </Col>
                </Row> 
            </Container>

        </>
    );
};

export default CrmMinhaLojaComprarSms; 