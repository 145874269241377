import axios from "axios";import authHeader from "./auth-header"; 
import AppConfig from "../Config/AppConfig";
 ; 

const API_URL = AppConfig.data.API_URL+"/fanfave-api/pagamento";

 class PagamentoService   {
   

  extrato(companyId : number) {    
    console.log(companyId);
    return axios
      .get(API_URL+ '/byCompanyId/'+companyId,  { headers: authHeader() });
  } 
}  
 
const pagamentoService = new PagamentoService();
export default pagamentoService;   
 