import axios from "axios";
import config from '../../Config/AppConfig';
import authHeader from "../auth-header";


const API_URL = config.data.API_URL + "/fanfave-api";
const DownloadService = {


  getSize(number) {
    if (number < 1024) return number + 'K';
    else {
      var totalMB = (number / 1024);
      var restoMB = (number % 1024);
      totalMB = totalMB + ',' + restoMB;
    } return totalMB + 'MB';
  },

  getUrlFoto() {
    const pictureLocal = localStorage.getItem('picture');
    if (pictureLocal === '') return null;
    if (pictureLocal === undefined) return null;
    if (pictureLocal === 'notfound.png') return null;
 
    return API_URL + "/download/image/" + pictureLocal;
  },

  getBase64Picture(picture) {
    if (picture === null) return `${require("../../assets/images/no_image.jpg")}`;  
    if (picture === undefined) return null;
    if (picture === 'notfound.png') return {}; 

    return API_URL + "/download/image/" + picture;
  },
   
  getImagem(codeLogo) {
    return API_URL + '/download/image/' + codeLogo;
  },

  listFolders() {
    return axios.get(API_URL + '/download/listFolders', { headers: authHeader() });
  },

  listFiles() {
    return axios.get(API_URL + '/download/listFiles', { headers: authHeader() });
  }
}

export default DownloadService;
