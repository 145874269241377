// Arquivo gerado de forma automática pelo gerador. 
import React, { Fragment, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Breadcrumbs, Btn } from '../../../../AbstractElements';
import { Form, Input, Col, Container, Row } from 'reactstrap';
import clientService from '../../../../Services/administrativo/administrativo.client.service';
import { toast } from "react-toastify";
import { New, TextSearch, Edit, List } from "../../../../Constant";

import SweetAlert from 'sweetalert2';
import { Delete, Edit2, Search } from 'react-feather';
import AdministrativoClientFormData from './client-form';
import AdministrativoClientListData from './client-list';

const AdministrativoClientPage = () => {
  const { t } = useTranslation();
  const [clients, setClients] = useState([]);
  const [option, setOption] = useState("");
  const [filtroName, setFiltroName] = useState(null);
  const [client, setClient] = useState({});
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});

  var requestFilter = {
    name: filtroName
  }

  const getListAll = async (e) => {
    setLoading(true);
    try {
      const response = await clientService.getFilter(requestFilter, page, size);
      var clientList = await response.data.content;
      setTotalRows(response.data.totalItens);
      const transformedItems = clientList.map((client) => ({
        ...client,
        action: (
          <div>

            <span>
              <Btn attrBtn={{ color: 'warning', type: 'button', onClick: (e) => btnEdit(client) }}>
                <Edit2 style={{ width: '18px', height: '18px' }} className='me-2' /></Btn>
            </span>
          </div>
        )
      }
      )
      );

      setClients(transformedItems);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
    return clientList;
  }

  const btnEdit = (client) => {
    setOption(Edit);
    setClient(client);
  }

  const deleteClient = (request) => {
    if (request.id != null && request.id > 0) {
      SweetAlert.fire({
        title: 'Are you right?',
        text: 'Uma vez excluído, você não poderá recuperar este registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          clientService.delete(request).then((response) => {
            var removeu = response.data;
            if (removeu) {
              SweetAlert.fire(
                'Removed!',
                'Registro foi apagado com sucesso.',
                'success'
              );
              getListAll();
              setOption(List);
              setClient({});
              return response;
            } else {
              SweetAlert.fire(
                'Erro!',
                'Erro ao remover registro.',
                'warning'
              );

              return response;
            }



          });

        } else {
          SweetAlert.fire(
            'The register went deleted with security!'
          );
        }
      });
    }
  }

  const initialize = () => {
    setError(null);
    setClient({ id: -1, name: "", corporateName: "", nickname: "", rg: "", phone: "", cellphone: "", mail: "", zipCode: "", address: "", number: "", complement: "", district: "", cpfCnpj: "", });
  }

  const btnCancel = () => {
    initialize();
    setOption(List);
  }


  const save = (request) => {


    return clientService.save(request)
      .then((response) => {
        initialize();
        setOption(List);
        toast.success("Empresa cadastrada com sucesso!");
        getListAll();
        return response;
      }).catch((error) => {
        setErrors(error.fieldErrors);
      });

  }

  const update = async (request) => {
    setLoading(true);
    if (request.id != null) {
      return clientService.update(request)
        .then((response) => {
          toast.success("Empresa alterada com sucesso!");
          getListAll();
          setOption(List);
          initialize();
          setLoading(false);
          return response;
        }).catch((error) => {
          toast.error("Erro ao logar = " + error.response.data);
          setError(error);
          setLoading(false);
        });
    }
  }
  const handlePageChange = (page) => {
    setPage(page);
    getListAll();
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPage(0);
    setSize(newPerPage);
    getListAll();
  };


  return (
    <Fragment>
      <Breadcrumbs mainTitle='Gestão de clientes' parent='Home' title='Clientes' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Form className='theme-form'>
              <div className='input-group flex-nowrap'>
                {/* <span className='btn btn-success input-group-text' onClick={() => btnNovo() }>
                        <Plus/>
                        {t(New)}</span>  */}

                <Input className='form-control-plaintext' type='search' placeholder={t(TextSearch)}
                  onChange={(e) => setFiltroName(e.target.value)} value={filtroName} />
                <span className='btn btn-primary input-group-text' onClick={() => {
                  setOption(List);
                  setPage(0);
                  getListAll(requestFilter);
                }}><Search /> {t(TextSearch)}</span>
              </div>
            </Form>
            <br /> 
            {
              option === New || option === Edit || option === Delete
                ?
                <AdministrativoClientFormData errors={errors} loading={loading} error={error} cancel={btnCancel} option={option} delete={deleteClient} update={update} save={save} data={client} />
                : option === List ?
                  <AdministrativoClientListData totalRows={totalRows} loading={loading} error={error} handlePerRowsChange={handlePerRowsChange} handlePageChange={handlePageChange} list={clients} /> : ''}
          </Col>
        </Row>
      </Container>

    </Fragment>
  );
};

export default AdministrativoClientPage;
