import React, { useEffect, useState } from 'react';
import { Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import productcompanyService from '../../../../../Services/administrativo/productcompany.service';
import { Alerts, Btn, H4, H5, H6 } from '../../../../../AbstractElements';
import SweetAlert from 'sweetalert2';
import clienteService from '../../../../../Services/administrativo/cliente.service';
import { useLocation, useNavigate } from 'react-router';

const ResgatarModal = ({ toggle, modal, setModalResgatar }) => {
    var companyLocal = JSON.parse(localStorage.getItem('company'));
    var cliente = JSON.parse(localStorage.getItem('cliente'));
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [regraId, setRegraId] = useState(null);
    const [value, setValue] = useState(0); 
    const [error, setError] = useState(null);
    const userId = JSON.parse(localStorage.getItem('userId'));
    const navigate = useNavigate();
    const location = useLocation();


    const fetchCompanyData = async () => {
        try {
            setLoading(true);
            const resp = await productcompanyService.findRegrasVigenteByCompanyId(companyLocal.id);
            const dados = resp.data;
            setProducts(dados);
            setLoading(true);
        } catch (error) {
            console.error("Erro ao buscar dados:", error);
        } finally {
            setLoading(false);
        }
    };


    const handleRadioButtonPress = (value, points) => {  
        setRegraId(value);
        setValue(points);
    };

    useEffect(() => {

        if (companyLocal) {
            fetchCompanyData();
        }

        if (modal) {
            setRegraId(null);
            setValue(0);
            setError(null);
        }
    }, []);



    const validarRequest = (request) => {
        if (!request.regraId || request.regraId <= 0) {
            setError('Por favor, selecione um produto');
            return false;
        }
        setError(null);  
        return true;
    };



    const salvarResgate = () => {
        var request = {
            clientId: cliente.client.id,
            companyId: cliente.company.id,
            points: value,
            rest: 0,
            value: value,
            userId: userId,
            regraId: regraId
        };
        
        const isValido = validarRequest(request);
        if (!isValido) {
            // Interrompe o fluxo se a validação falhar
            return;
        }

        return clienteService.resgatar(request)
            .then(() => {
                SweetAlert.fire({ title: 'Sucesso', text: 'Resgate realizado com sucesso', icon: 'success', });
                setModalResgatar(false); 
                navigate(location.pathname, { replace: true }); 
            })
            .catch((error) => { 
                setError(error.response.data.message);
            });
        
    };

    return (loading ? 'Carregando' : 
        <> <Form className="theme-form mega-form" id="formCountry" name="formCountry" method="POST">
            <Modal isOpen={modal} toggle={toggle} className='modal-body' centered={true}  size='lg' >
                <ModalHeader toggle={toggle}> Registrar Resgate </ModalHeader>
                <ModalBody>
                   
                        <fieldset>
                            {!products || products.length === 0 ? <Alerts attrAlert={{ color: 'secondary ' }} >
                                <H4 attrH4={{ className: 'alert-heading' }} >{'Desculpe'}</H4>
                                <H6 attrH6={{ className: 'alert-heading' }} >{'Não encontramos nenhuma regra de resgate ativa configurada!'}</H6>
                            </Alerts> : <></>} 
                            {products && products.map((produto) => (
                                <div className="row align-items-center mt-2" key={produto.id}>
                                    <div className="col-md-1">
                                        <Label for="edo-ani2">
                                            <Input className="radio_animated" disabled={(cliente.points - produto.points) < 0}
                                                id="edo-ani2" type="radio" name="rdo-ani" onChange={() => handleRadioButtonPress(produto.id, produto.points)} value={regraId} />
                                            {Audio}
                                        </Label> 
                                    </div>
                                    <div className="col-md-7  text-left">
                                        <H5>{produto.description} ({produto.points} pontos)</H5>
                                    </div>
                                    <div className="col-md-4 text-center">
                                        <H5><b>{(cliente.points - produto.points) < 0 ? "Falta " + ((cliente.points - produto.points) * -1) + " pontos" : "Liberado"}</b></H5>
                                    </div>
                                </div>
                            ))} 

                        </fieldset>
                        {error  && (<Alerts attrAlert={{ color: 'secondary ' }} >
                            <H6 attrH6={{ className: 'alert-heading' }} >{error}</H6>
                        </Alerts> )} 
 
                </ModalBody>
                <ModalFooter>
                {products && products.length > 0 ? <Btn
                    value="Pontuar"
                    attrBtn={{
                        type: 'button',
                        color: 'success',
                        className: 'btn-hover-effect w-100',
                        name: 'clickedButton',
                        onClick: salvarResgate
                    }}>
                    Salvar
                </Btn> : <></>}
                </ModalFooter>
            </Modal> </Form>
        </>
    );
};
export default ResgatarModal;