import React, { useContext } from 'react';
import { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Callback from '../Auth/Callback';
import Loader from '../Layout/Loader';
import LayoutRoutes from '../Route/LayoutRoutes';
import PrivateRoute from './PrivateRoute';
import { authRoutes } from './AuthRoutes';
import Error404 from '../Components/Pages/ErrorPages/ErrorPage404';
import AuthContext from '../_helper/Auth';
import LoginTab from '../Auth';
import SweetAlert from 'sweetalert2';

const Routers = () => {
  const { authenticated } = useContext(AuthContext);
  const jwt_token = localStorage.getItem('token');
  const  accessToken  = jwt_token ?? null ; 


  const liberado = window.location.origin === 'http://localhost:3000';

  const searchParams = new URLSearchParams(window.location.search);
  const allowDevTools = searchParams.has('devtools');
 // let deferredPrompt;
 

  useEffect(() => {
    let abortController = new AbortController();

    console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
    console.disableYellowBox = true;



    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevenir que o navegador mostre o prompt automaticamente
      e.preventDefault();

      // Armazenar o evento para exibir posteriormente
      //deferredPrompt = e;

      // Mostrar o botão de instalação ou automaticamente disparar o prompt
      //showInstallPrompt();
    });
 /*
    // Função para mostrar o prompt de instalação
    function showInstallPrompt() {
      if (deferredPrompt) {
        deferredPrompt.prompt(); // Exibe o prompt de instalação
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('Usuário aceitou instalar o PWA');
          } else {
            console.log('Usuário recusou instalar o PWA');
          }
          deferredPrompt = null; // Resetar o prompt
        });
      }
    } */

    if (!allowDevTools && !liberado) {

      document.addEventListener('keydown', function (event) {
        if (event.key === 'F12' || (event.ctrlKey && event.shiftKey && (event.key === 'I' || event.key === 'J'))) {
          event.preventDefault();
          SweetAlert.fire({
            title: 'Desculpe, ferramenta de Desenvolvedor desativada!',
            confirmButtonText: 'OK',
            reverseButtons: true,
          });
        }
      });


      // Bloquear o clique com o botão direito do mouse
      document.addEventListener('contextmenu', function (event) {
        event.preventDefault();
        SweetAlert.fire({
          title: 'Desculpe, essa opção não está disponível!',
          confirmButtonText: 'OK',
          reverseButtons: true,
        });
      });



    }

    return () => {
      abortController.abort();
    };
  }, []);

  return (


    <BrowserRouter basename='/' >

      <Suspense fallback={<Loader />}>
        <Routes>
          <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<LoginTab />} />
          <Route exact path={'/'} element={<PrivateRoute />}>
            {authenticated && accessToken ? (
              <>
                <Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`${process.env.PUBLIC_URL}/login`} />} />
              </>
            ) : (
              <>
                <Route exact path={`${process.env.PUBLIC_URL}/lock`} element={<Navigate to={`${process.env.PUBLIC_URL}/lock`} />} />
              </>
            )}
            <Route path={`/*`} element={<LayoutRoutes />} />
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/callback`} render={() => <Callback />} />
          {authRoutes.map(({ path, Component }, i) => (
            <Route path={path} element={Component} key={i} />
          ))}
          <Route path='/*' element={<Error404 />} /> {/* Rota de fallback para a página 404 */}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routers;
