import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component'; 
import companyService from '../../../../../Services/administrativo/company.service';
import { extratoSmsListColumns } from '../../../../../Data/administrativo/ExtratoSmsList';
import SkeletonTable from '../../../../../Layout/SkeletonTable';
import DateService from '../../../../../Services/utils/date.service';
import { FaCalendarTimes, FaCheck, FaPhone, FaSms, FaTimes } from 'react-icons/fa';

const ExtratoSms = () => {
  var companyLocal = JSON.parse(localStorage.getItem('company'));
  const [mensagens, setMensagens] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const fetchCompanyListMensagens = async () => {
      try {
        const response = await companyService.getExtratoSms(companyLocal.id);
        const data = await response.data;
        setMensagens(data);
      } catch (error) {
        console.error('Error to load options:', error);
        
      } finally {
        setLoading(false);
      }
    };
    fetchCompanyListMensagens();

  }, []);





  return (mensagens.length === 0 ? 'Nenhum registro encontrado' :
    <>
      <div className='table-responsive product-table'>{
        loading ? (
          <div>
            <SkeletonTable rows={10} columns={7} />
          </div>
          ) :  
          
          <table className="table table-bordered table-striped table-hover table-responsive">
          <thead className="table-dark">
            <tr>
              <th className="text-center">Id</th>
             
              <th className="text-end">
                <FaPhone /> Número
              </th>
              <th className="text-center">
                <FaSms /> Conteúdo
              </th>
              <th className="text-center">
                <FaCalendarTimes /> Data/Hora</th>
              <th className="text-end">Custo</th>
              <th className="text-center">Cobrado?</th>
            </tr>
          </thead>
          <tbody>
            {mensagens.map((p) => (
              <tr key={p.code}>
                <td className="text-center">{p.id}</td>
                
                <td className="text-end">{p.numero}</td>
                <td className="text-center">{p.mensagem}</td>
                <td className="text-center">{DateService.getDateTime(p.dataHora)}</td>
                <td className="text-end">R$ {p.custo.toFixed(2)}</td>
                <td className="text-center">
                  {p.cobrado ? (
                    <FaCheck className="text-success" title="Cobrado" />
                  ) : (
                    <FaTimes className="text-danger" title="Não Cobrado" />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        
      }

      </div>

    </>

  );
};

export default ExtratoSms; 