import { useState } from "react";
import { Col, Row, Container, Input, Form, FormGroup, Label, Card, CardBody } from "reactstrap"
import { Btn } from "../../../../AbstractElements";
import MaskedInput from "react-text-mask";
import UtilService from "../../../../Services/utils/util.service";
import SweetAlert from 'sweetalert2';


const CellPhoneMask = ({ inputRef, ...otherProps }) => (
    <MaskedInput
      {...otherProps}
      inputRef={inputRef} 
      mask={['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
);

const RemocaoDadosPage = () => {
    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [telefone, setTelefone] = useState("");
    const [motivo, setMotivo] = useState("");

    var request = {
        nome: nome,
        email: email,
        telefone: telefone,
        motivo: motivo,
    };

    const update = () => {
        UtilService.createRemocaoDadosPage(request).then((response) => {
            SweetAlert.fire({
                title: 'Registro cadastrado com sucesso!',
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'OK',
                reverseButtons: true,
            });
            limparCampos();
        }).catch((error) => {
            SweetAlert.fire({ 
                title: 'Desculpe', 
                text: 'Ocorreu um erro ao salvar o cliente', 
                icon: 'danger' 
            });
        });
    };
    const limparCampos = () => {
        setNome(null);
        setEmail(null);
        setTelefone(null);
        setMotivo(null);
    }

    // Função de validação
    const validateForm = (e) => {
        e.preventDefault();
        if (!nome || !email || !telefone || !motivo) {
            SweetAlert.fire({
                title: 'Campos obrigatórios',
                text: 'Por favor, preencha todos os campos antes de enviar a solicitação.',
                icon: 'warning',
                confirmButtonText: 'OK'
            });
            return;
        }
        update(); // Envia os dados se todos os campos estiverem preenchidos
    };

    return (
        <>
            <Container fluid={true} className='faq-section'>
                <Row>
                    <Col xs="12">
                        <div className="knowledgebase-bg"
                            style={{ backgroundImage: `url(${require('../../../../assets/images/knowledgebase/bg_1.jpg')})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'block' }}>
                        </div>
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <div className="knowledgebase-search">
                                    <Container fluid={true} className='p-0 login-page'>
                                        <Row>
                                            <Col xs='12'>
                                                <h1>Solicitação de Remoção de Dados Pessoais</h1>
                                                <p>Aqui você pode solicitar a remoção dos seus dados pessoais relacionados ao aplicativo <strong>fanfave app</strong>.</p>
                                                <Form className="theme-form mega-form" id="formCountry" name="formCountry" method="POST">
                                                    <FormGroup>
                                                        <Label className="col-form-label"> Nome completo</Label>
                                                        <Input className="form-control btn-square " type="text" onChange={(e) => setNome(e.target.value)} value={nome} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label className="col-form-label">E-mail associado à conta:</Label>
                                                        <Input className="form-control btn-square " type="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label className="col-form-label">Telefone associado à conta:</Label>
                                                        <Input tag={CellPhoneMask} className="form-control btn-square" type="text" onChange={(e) => setTelefone(e.target.value)} value={telefone} />
                                                    </FormGroup>
                                                    <Label htmlFor="message">Descrição da solicitação:</Label><br />
                                                    <textarea className='form-control' rows="4"
                                                        value={motivo} maxLength={160}
                                                        onChange={(e) => setMotivo(e.target.value)}
                                                        style={{ height: '100px' }} />
                                                    
                                                    {/* Verificação de campos preenchidos ao clicar */}
                                                    <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit", onClick: (e) => validateForm(e) }}>
                                                        {'Enviar Solicitação'}
                                                    </Btn>
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </>
    );
}

export default RemocaoDadosPage;
