

import DateService from "../../Services/utils/date.service";

  
export const extratoResgateClienteColumns = [
  {
    name: 'Data',
    selector: (row) => DateService.getDate(row.data),
    sortable: true,
    center: true,  
  },

  {
    name: 'Produto',
    selector: (row) => row.product,
    sortable: true,
    center: true, 
  },
  {
    name: 'Valor',
    selector: (row) =>  new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(row.valor) ,
    sortable: true,
    center: true, 
  },
  {
    name: 'Pontos',
    selector: (row) => row.pontos,
    sortable: true,
    center: true, 
  },
  {
    name: 'Nota',
    selector: (row) => row.nota,
    sortable: true,
    center: true, 
  },
  

  {
    name: 'Responsável',
    selector: (row) => row.responsavel,
    sortable: true,
    center: true,
    minWidth: '150px',
    maxWidth: '150px',
  }, 
];
