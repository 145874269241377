import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

if (!localStorage.getItem('i18nextLng')) {
    localStorage.setItem('i18nextLng', 'br');
 }

 

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            en: {
                translations: {
                    'General': 'General',
                    'Dashboards,Widgets': 'Dashboards,Widgets',
                    'Dashboard': 'Dashboard',
                    'Default': 'Default',
                    'Ecommerce': 'Ecommerce',
                    'Widgets': 'Widgets',
                    'Chart': 'Chart',
                    'Applications': 'Applications',
                    'Ready to use Apps': 'Ready to use Apps',
                    'New': 'New',
                    'Banks': 'Banks',
                    'Settings': 'Settings',
                    'Bank Management': 'Bank Management',
                    'Search': 'Search',
                    'Loading': 'Loading',
                    'Country': 'Country',
                    'State': 'State',
                    'City': 'City',
                    'Account': 'Account',
                    'Yes': 'Yes',
                    'No': 'No',
                    'Active': 'Active',
                    'Inactive': 'Inactive',
                    'Delete': 'Delete',
                    'Edit': 'Edit',
                    'Enter your user & password to login' : 'Enter your user & password to login',
                    'Try It Real Time' : 'Try It Real Time',
                    'Login' : 'Login',
                    'Email Address' : 'Email Address',
                    'Create Account' : 'Create Account',
                    'Already have an account?' : 'Already have an account?',
                    'Agree with' : 'Agree with',
                    'Privacy Policy' : 'Privacy Policy',
                    'Password' : 'Password',
                    'Your Name' : 'Your Name',
                    'Fist Name': 'Fist Name',
                    'Last Name' : 'Last Name',
                    'Enter your personal details to create account' : 'Enter your personal details to create account',
                    'Create your account' : 'Create your account',
                    'Log Out' : 'Log Out',
                    'Profile' : 'Profile',
                    'Don\'t have account?': 'Don\'t have account?',
                    'Forgot password?' : 'Forgot password?',
                    'Remember password' : 'Remember password',
                    'You enter wrong password or username!' : 'You enter wrong password or username!',
                    'Sorry!' : 'Sorry!',
                    'Enter Your Mobile Number' : 'Enter Your Mobile Number',
                    'Congratulations!' : 'Congratulations!',
                    'User already exists in the system!' : 'User already exists in the system!',
                    'User must have valid email address': 'User must have valid email address',
                    'Reset Your Password' : 'Reset Your Password',
                    'Enter Your Email' : 'Enter Your Email',
                    'Send' : 'Send',
                    'Invalid mail informed! This field is required!' : 'Invalid mail informed! This field is required!',
                    'New Password' : 'New Password'
                }
            },
            es: {
                translations: {
                    'General': 'General',
                    'Dashboards,Widgets': 'Cuadros de mando, widgets',
                    'Dashboard': 'Tablero',
                    'Default': 'Defecto',
                    'Ecommerce': 'Comercio electrónico',
                    'Widgets': 'Widgets',
                    'Chart': 'Gráfico',
                    'Applications': 'Aplicaciones',
                    'Ready to use Apps': 'Gereed om programme te gebruik',
                    'New': 'Nuevo',
                    'Banks': 'Bancos',
                    'Settings': 'Settings',
                    'Bank Management': 'Bank Management',
                    'Search': 'Search',
                    'Loading': 'Loading',
                    'Country': 'Country',
                    'State': 'State',
                    'City': 'City',
                    'Account': 'Account',
                    'Yes': 'Yes',
                    'No': 'No',
                    'Active': 'Active',
                    'Inactive': 'Inactive',
                    'Delete': 'Eliminar',
                    'Edit': 'Alterar',
                    'Enter your user & password to login' : 'Enter your user & password to login',
                    'Try It Real Time' : 'Try It Real Time',
                    'Login' : 'Login',
                    'Email Address' : 'Email Address',
                    'Create Account' : 'Create Account',
                    'Already have an account?' : 'Already have an account?' ,
                    'Agree with' : 'Agree with' ,
                    'Privacy Policy' : 'Privacy Policy',
                    'Password' : 'Password',
                    'Your Name' : 'Your Name',
                    'Fist Name': 'Fist Name',
                    'Last Name' : 'Last Name',
                    'Enter your personal details to create account' : 'Enter your personal details to create account',
                    'Create your account' : 'Create your account',
                    'Log Out' : 'Log Out',
                    'Profile' : 'Profile',
                    'Don\'t have account?': 'Don\'t have account?',
                    'Forgot password?' : 'Forgot password?',
                    'Remember password' : 'Remember password',
                    'You enter wrong password or username!' : 'You enter wrong password or username!',
                    'Sorry!' : 'Sorry!',
                    'Enter Your Mobile Number' : 'Enter Your Mobile Number',
                    'Congratulations!' : 'Congratulations!',
                    'User already exists in the system!' : 'User already exists in the system!',
                    'User must have valid email address': 'User must have valid email address',
                    'Reset Your Password' : 'Reset Your Password',
                    'Enter Your Email' : 'Entre com seu e-mail',
                    'Send' : 'Enviar',
                    'Invalid mail informed! This field is required!' : 'Invalid mail informed! This field is required!',
                    'New Password' : 'Nova senha'
                }
            },
           
            br: {
                translations: {
                    'General': 'Geral',
                    'Dashboards,Widgets': 'Painéis, Widgets',
                    'Dashboard': 'Dashboard',
                    'Default': 'Padrão',
                    'Ecommerce': 'Comércio eletrônico',
                    'Widgets': 'Widgets',
                    'Chart': 'Gráfico',
                    'Applications': 'Formulários',
                    'Ready to use Apps': 'Aplicativos prontos para usar',
                    'New': 'Novo',
                    'Banks': 'Bancos',
                    'Settings': 'Configurações',
                    'Bank Management': 'Gestão de Banco',
                    'Search': 'Pesquisa',
                    'Loading': 'Carregando',
                    'Country': 'País',
                    'State': 'Estado',
                    'City': 'Cidade',
                    'Account': 'Minha conta',
                    'Yes': 'Sim',
                    'No': 'Não',
                    'Active': 'Ativo',
                    'Inactive': 'Inativo',
                    'Delete': 'Apagar',
                    'Edit': 'Alterar',
                    'Enter your user & password to login' : 'Digite seu nome de usuário e senha para fazer login',
                    'Try It Real Time' : 'Experimente em tempo real',
                    'Login' : 'Entrar',
                    'Email Address' : 'Endereço de Email',
                    'Create Account' : 'Criar conta',
                    'Already have an account?' : 'Já tem uma conta?'  ,
                    'Agree with' : 'Concordo com',
                    'Privacy Policy' : 'Políticas de Privacidade',
                    'Password' : 'Senha',
                    'Your Name' : 'Seu Nome',
                    'Fist Name': 'Primeiro Nome',
                    'Last Name' : 'Sobrenome',
                    'Enter your personal details to create account' : 'Insira seus dados pessoais para criar uma conta',
                    'Create your account' : 'Crie sua conta',
                    'Log Out' : 'Sair',
                    'Profile' : 'Perfil',
                    'Don\'t have account?': 'Não tem uma conta?',
                    'Forgot password?' : 'Esqueceu sua senha?',
                    'Remember password' : 'Lembrar senha'    ,
                    'You enter wrong password or username!' : 'Você entrou com usuário e senha incorretos!',
                    'Sorry!' : 'Desculpe!'    ,
                    'Enter Your Mobile Number' : 'Informe seu número de telefone',
                    'Congratulations!' : 'Parabéns!'     ,
                    'User already exists in the system!' : 'Usuário já existe no sistema!' ,
                    'User must have valid email address': 'Usuário deve ter um endereço de e-mail válido',
                    'Reset Your Password' : 'Resetar sua senha',
                    'Enter Your mail' : 'Entre com seu e-mail',
                    'Send' : 'Enviar',
                    'Invalid mail informed! This field is required!' : 'E-mail informado é inválido! Esse campo é obrigatório!',
                    'Return to ' : 'Retornar para ',
                    'New Password' : 'Nova senha',
                    'Repeat Password' : 'Repita a senha',
                    'done' : 'Pronto',
                    'preparing' : 'Preparando...',
                    'getting_upload_params' : 'Carregando dados do upload',
                    'headers_received' : 'Cabeçalho recebido',
                    'removed' : 'Removido',
                    'error_upload' : 'Erro ao realizar o upload',
                    'uploading': 'Enviando...',
                    
                }
            } 

            
        },
        fallbackLng: 'br',
        debug: false,
        lng: 'br', 

        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
