import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Alerts, Btn, H4, H6} from '../AbstractElements';
import { EmailAddress, ForgotPassword, Password, Login, Sorry } from '../Constant'; 
 
import authService from '../Services/auth.service';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthContext from '../_helper/Auth';
import Loader from '../Layout/Loader';


const LoginTab = () => {
  const { t } = useTranslation();
  const { setUser, setAuthenticated } = useContext(AuthContext);
  const emailLogin  = JSON.parse(localStorage.getItem('emailLogin'));
 
  const [isCheckedLembrarEmail, setCheckedLembrarEmail] = useState(emailLogin !== null ? true : false);
  const [username, setUsername] = useState( emailLogin);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [error, setError] = useState(null);
  const [theme, setTheme] = useState('Default');
  const history = useNavigate();

  let mobile = window.innerWidth <= 768 ? true : false;

  const handleCheckboxChange = (event) => {
    setCheckedLembrarEmail(event.target.checked);
  };

  useEffect ( () => {
    mobile = window.innerWidth <= 768 ? true : false;
  }, [])
 

  const login = (e) => {
    setLoading(true);
    return authService.login(username, password)
      .then((response) => {
        let user = response.data;
        setUser(user); 
        const actualModule = user.module ? user.module : 'VISITANTE'; 
        localStorage.setItem('token', user.token);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('roles', JSON.stringify(user.roles));
        localStorage.setItem('userId', JSON.stringify(user.id));
        
       
        if (isCheckedLembrarEmail) {
          localStorage.setItem('emailLogin', JSON.stringify(username));
        } else {
          localStorage.removeItem('emailLogin');
        }

        localStorage.setItem('company', JSON.stringify(user.company ? user.company : null));
        localStorage.setItem('name', JSON.stringify(user.name));
        localStorage.setItem('authenticated', true);
        setTheme(user.theme == null ? 'Default' : user.theme);
        localStorage.setItem('theme', JSON.stringify(theme));
        localStorage.setItem('Name', user.name);
        localStorage.setItem('profileURL', user.picture);
        localStorage.setItem('lightOn', user.lightOn ? user.lightOn : true);
        localStorage.setItem('actualModule', actualModule);
        localStorage.setItem('picture', user.picture ? user.picture : '');
        localStorage.setItem('refreshToken', user.refreshToken);
        setAuthenticated(true);
        setLoading(false);
       history(`${process.env.PUBLIC_URL}/${actualModule.toLowerCase()}/dashboard`);
         return user;

      }).catch((errorResponse) => { 
        if (errorResponse.status === 404) {
          setError("Você entrou com usuário e senha incorretos!");
        } else if (errorResponse.code === 'ERR_NETWORK') {
          setError("Erro de conexão com o servidor. Procure o responsável pelo sistema");
        } else if (errorResponse.response) { 
          localStorage.removeItem('token');
          localStorage.removeItem('profileURL');
          setError(errorResponse.response.data.message);
        }

      }).finally((f) => {
        setLoading(false);
      });
  };

  return (
    loading ? (
      <Loader/ > 
    ) :
    <section>
      <Container fluid={true}>
        <Row> { !mobile ? <Col xl='7' className='b-center bg-size' 
          style={{ backgroundImage: `url(${require('../assets/images/login/2.png')})`, 
           backgroundSize: 'cover', backgroundPosition: 'center', display: 'block'     }} /> : '' }
            
       { !mobile ?  <Col xl='5 p-0'>  
     
        <div className='login-card'>
          <div className='login-main'>
            <Form className='theme-form'>
                <center > 
                 <H4>Login</H4>
                </center>
              {error ? <Alerts attrAlert={{ color: 'secondary ' }} >
                <H4 attrH4={{ className: 'alert-heading' }} >{t(Sorry)}</H4>
                <H6 attrH6={{ className: 'alert-heading' }} >{t(error)}</H6>
              </Alerts> : ''}
              <FormGroup>
                <Label className='col-form-label'>{t(EmailAddress)}</Label>
                <Input className='form-control' type='email' onChange={(e) => setUsername(e.target.value)} value={username} />
              </FormGroup>
              <FormGroup className='position-relative'>
                <Label className='col-form-label'>{t(Password)} {mobile ? 'Sim' : 'Não'}</Label>
                <div className='position-relative'>
                  <Input className='form-control' type={togglePassword ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)} required={true} value={password} />
                  <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
                    <span className={togglePassword ? '' : t('show')}></span>
                  </div>
                </div>
              </FormGroup>
              <div className='position-relative form-group mb-0'>
                <div className='checkbox'>
                  <Input id='checkbox1' type='checkbox'  checked={isCheckedLembrarEmail} onChange={handleCheckboxChange}/>
                  <Label className='text-muted' for='checkbox1' >
                    {t('Lembrar e-mail')}
                  </Label>
                </div>

                <a className='link' href={`${process.env.PUBLIC_URL}/forget-pwd`}>
                  {t(ForgotPassword)}
                </a>
                <Btn attrBtn={{ color: 'primary', className: 'd-block w-100 mt-2', onClick: (e) => login(username, password) }}>{t(Login)}</Btn>
              </div>

            </Form>
          </div>
        </div>
        </Col> : 
        <Col>  
          <div className='login-card-mobile'>
            <Form className='theme-form'>
              <H4><center>{Login}</center></H4>
              {error ? <Alerts attrAlert={{ color: 'secondary ' }} >
                <H4 attrH4={{ className: 'alert-heading' }} >{t(Sorry)}</H4>
                <H6 attrH6={{ className: 'alert-heading' }} >{t(error)}</H6>
              </Alerts> : ''}
              <FormGroup>
                <Label className='col-form-label'>{t(EmailAddress)}</Label>
                <Input className='form-control' type='email' onChange={(e) => setUsername(e.target.value)} value={username} />
              </FormGroup>
              <FormGroup className='position-relative'>
                <Label className='col-form-label'>{t(Password)} {mobile ? 'Sim' : 'Não'}</Label>
                <div className='position-relative'>
                  <Input className='form-control' type={togglePassword ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)} required={true} value={password} />
                  <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
                    <span className={togglePassword ? '' : t('show')}></span>
                  </div>
                </div>
              </FormGroup>
              <div className='position-relative form-group mb-0'>
                <div className='checkbox'>
                  <Input id='checkbox1' type='checkbox'  checked={isCheckedLembrarEmail} onChange={handleCheckboxChange}/>
                  <Label className='text-muted' for='checkbox1' >
                    {t('Lembrar e-mail')}
                  </Label>
                </div>

                <a className='link' href={`${process.env.PUBLIC_URL}/forget-pwd`}>
                  {t(ForgotPassword)}
                </a>
                <Btn attrBtn={{ color: 'primary', className: 'd-block w-100 mt-2', onClick: (e) => login(username, password) }}>{t(Login)}</Btn>
              </div>

            </Form>
          </div>
          </Col> }
        </Row>

        </Container></section>
  );
};

export default LoginTab;
