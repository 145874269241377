import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { H5, H6 } from '../../../../../AbstractElements';
import { PlusCircle } from 'react-feather';
import axios from 'axios';
import ReactQuill from 'react-quill';
import clienteService from '../../../../../Services/administrativo/cliente.service';
import SweetAlert from 'sweetalert2';

const EnviarMensagemGeralPushNotificationForm = (props) => {
    const company = JSON.parse(localStorage.getItem('company'));
    const [editorContent, setEditorContent] = useState(''); 
    
    const [loading, setLoading] = useState(false);
    const [EnviandoPushNotification, setEnviandoPushNotification] = useState(false);
    const [titulo, setTitulo] = useState('');  
    const [urlImagem, setUrlImagem] = useState(null);  

     
    const cliente = props.cliente;



    // Função para enviar o e-mail
    const enviarNotification = async () => {
        setEnviandoPushNotification(true);
        try {

            const response = await clienteService.enviarPushNotificationGeral(company.id, {
                titulo: titulo,
                body: editorContent,
                companyId: company.id,
                userId: cliente ? cliente.userId : null, 
                urlImagem : urlImagem,
                companyId: company.id 
            });

            console.log(response);

            if (response.status === 200) {
                SweetAlert.fire({
                    title: 'Mensagem Push Notification enviada com sucesso!',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    reverseButtons: true,
                }).then((result) => {
                    setEditorContent('');
                    setTitulo('');
                    setUrlImagem('');
                });
            }

        }catch (error) {
            console.error('Erro ao enviar notificação:', error);
            alert('Erro ao enviar notificação.');

            setEnviandoPushNotification(false);

        }
            
        setEnviandoPushNotification(false);
    };

    return (loading ? 'Carregando dados' :
        <> 
            <Row>
                <Col>
                    <FormGroup>
                        <Label className="col-form-label">Título:</Label>

                        <Input className="form-control btn-square " type="text" onChange={(e) => setTitulo(e.target.value)} value={titulo} />
                    </FormGroup>
                </Col>

            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label className="col-form-label">Url da imagem:</Label>

                        <Input className="form-control btn-square " type="text" onChange={(e) => setUrlImagem(e.target.value)} value={urlImagem} />
                    </FormGroup>
                </Col>

            </Row>
            <Row>
                <Col>
                    <label htmlFor="emailDestinatario">Conteúdo</label>
                    <textarea className='form-control' 
    value={editorContent} maxLength={160}
    onChange={(e) => setEditorContent(e.target.value)} // Corrige para atualizar o estado com o valor do textarea
    style={{ height: '100px' }} // Define a altura do editor
/>
 

                </Col>

            </Row>
            <br />
            <br />
            {
                EnviandoPushNotification ? 
                    <button type="button" className="btn btn-success disabled w-100 btn-sm input-group-text" >
                        <H6><PlusCircle /> Enviando... </H6>
                    </button> :
                    <button type="button" className="btn btn-success w-100 btn-sm input-group-text" onClick={enviarNotification}>
                        <H6><PlusCircle /> Enviar </H6>
                    </button>
            }

        </>
    );
}
export default EnviarMensagemGeralPushNotificationForm;
 