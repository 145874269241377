import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, CardHeader, CardBody, Card } from 'reactstrap';
import { Btn, H5 } from '../../../../AbstractElements';
import { CardFooter } from 'reactstrap'
import { Cancel, Delete, Edit, New } from "../../../../Constant";
import personTypeService from '../../../../Services/administrativo/personType.service';
import personService from '../../../../Services/administrativo/person.service';
import MaskedInput from 'react-text-mask';
import dateService from '../../../../Services/utils/date.service';
import CompanyPersonForm from './company-person';
import AlertError from '../../../../CommonElements/AlertError';

 


const CpfMask = ({ inputRef, ...otherProps }) => (
  <MaskedInput
    {...otherProps}
    inputRef={inputRef} 
    mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
    placeholderChar={'\u2000'}
    showMask
  />
);




const CnpjMask = ({ inputRef, ...otherProps }) => (
  <MaskedInput
    {...otherProps}
    inputRef={inputRef} 
    mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
    placeholderChar={'\u2000'}
    showMask
  />
);



const CompanyFormData = (props) => {
  const [id, setId] = useState(props.data.id);
  const [personId, setPersonId] = useState(props.data.person != null ? props.data.person.id : -1);
  const [cpfCnpj, setCpfCnpj] = useState(props.data.person != null ? props.data.person.cpfCnpj : '');
  const [personType, setPersonType] = useState(props.data.person ? props.data.person.personType : 'FISICA');
  const [listOfPersonType, setListOfPersonType] = useState([]);
  const [rg, setRg] = useState(props.data.rg);
  const [name, setName] = useState(props.data.name);
  const [dateOfBirth, setDateOfBirth] = useState(dateService.getDate(props.data.person ? props.data.person.dateOfBirth : null));
  const [initialDate, setInitialDate] = useState(dateService.getDate(props.data.initialDate));
  const corporateName  = props.data.corporateName;
  const nickname  = props.data.nickname;
  const [phone, setPhone] = useState(props.data.phone);
  const [cellphone, setCellphone] = useState(props.data.cellphone);
  const [mail, setMail] = useState(props.data.mail);
  const [zipCode, setZipCode] = useState(props.data.zipCode);
  const [address, setAddress] = useState(props.data.address);
  const logoMarca  = props.data.logo;
  const [number, setNumber] = useState(props.data.number);
  const [complement, setComplement] = useState(props.data.complement);
  const [district, setDistrict] = useState(props.data.district);
 
  const [cityId, setCityId] = useState(props.data.city ? props.data.city.id : null);
  const fantasyName = props.data.fantasyName;
  
  
  const [somenteLeitura, setSomenteLeitura] = useState(personId > 0 ? true : false);
  const [pesquisouPessoa, setPesquisouPessoa] = useState(personId > 0 ? true : false);
  const [person, setPerson] = useState(props.data.person);
  const [cpfCnpjInvalido, setCpfCnpjInvalido] = useState(false);

  const option = props.option;

  var requestPerson = {

    id: personId,
    name: name,
    corporateName: corporateName,
    nickname: nickname,
    rg: rg,
    phone: phone,
    cellphone: cellphone,
    mail: mail,
    zipCode: zipCode,
    address: address,
    number: number,
    complement: complement,
    district: district,
    cpfCnpj: cpfCnpj,
    city: cityId,
    dateOfBirth: dateOfBirth,
    personType: personType,
    fantasyName: fantasyName,
    initialDate: initialDate,

  }

  var request = {
    id: id,
    status: 'ACTIVE',
    person: requestPerson
  }

  useEffect(() => {
    const fetListOfPersonType = async () => {
      try {
        const response = await personTypeService.getAll();
        const data = await response.data;
        setListOfPersonType(data);
      } catch (error) {
        console.error('Error to load options:', error);
      }
    };
    fetListOfPersonType();
  }, []);

  const findPersonByCpfCnpj = async (request) => {
    setPesquisouPessoa(false);

    var cpfCnpjFilter = cpfCnpj.replaceAll('.', '').replaceAll('.', '').replaceAll('-', '').replaceAll('/', '').trim();
    if (cpfCnpjFilter.length !== 11 && cpfCnpjFilter.length !== 14) {
      setCpfCnpjInvalido(true);
    } else {
      try {
        await personService.findByCpfCnpj(cpfCnpjFilter).then(response => {
          console.log(response);

          const data = response.data;
          setPerson(data);
          setPersonId(data.id);
          setRg(data.rg);
          setName(data.name);
          setDateOfBirth(data.dateOfBirth);
          setZipCode(data.zipCode);
          setAddress(data.address);
          setNumber(data.number);
          setComplement(data.complement);
          setDistrict(data.district);
          setPhone(data.phone);
          if (data.city == null ) {
            setCityId(null);
          } else {
            setCityId(data.city.id);
          }
         

         
          setCellphone(data.cellphone);
          setMail(data.mail);
          
          setPesquisouPessoa(true);
          setSomenteLeitura(true);
          setCpfCnpjInvalido(false);
        });
       } catch (error) {

        if (error.response != null && error.response.status === 404) {
          setPersonId(null);
          setPerson({ id: -1, name: "", corporateName: "", nickname: "", rg: "", phone: "", cellphone: "", mail: "", zipCode: "", address: "", number: "", complement: "", district: "", cpfCnpj: "", });
          setSomenteLeitura(false);
          setPesquisouPessoa(true);
        } else {
          console.error(error);
        }

      } 
    }
  }


  return (
    <Fragment>
      <Col sm="12">
        {props.loading ? (
          <p>Loading...</p>
        ) : props.error ? (
          <p>Error: {props.error.message}</p>
        ) : ''
        }
       
          <Card className={'card-absolute'}>
            <CardHeader className={'bg-primary'}>
              <H5>{option} Dados cadastrais</H5>
            </CardHeader>
            <CardBody>
            <Form className="theme-form mega-form" id="formCountry" name="formCountry" method="POST">
 


              <Row>
                <Col md={3}>
                  <FormGroup>
                    <Label className="col-form-label">Id</Label>
                    {option !== New ?
                      <Input className="form-control  disabled" style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}} type="text" placeholder="Id" onChange={(e) => setId(e.target.value)} value={id} readOnly /> :
                      <Input className="form-control  disabled" type="text" placeholder="Código será gerado automaticamente" disabled readOnly />
                    }
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label className="col-form-label">Person Type:</Label>

                    <select className='form-control' size='1' style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}} value={personType} onChange={(e) => { setPersonType(e.target.value); setPesquisouPessoa(false); setPerson({}); }}>
                      {listOfPersonType.map((personTypeItem) => (
                        <option value={personTypeItem} key={personTypeItem} selected={personTypeItem === personType} >{personTypeItem}</option>
                      ))}
                    </select>
                  </FormGroup>

                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label className="col-form-label">{personType === 'FISICA' ? 'CPF' : 'CNPJ'}</Label>
                    <Input className="form-control " style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}} tag={personType === 'FISICA' ? CpfMask : CnpjMask} type="text" placeholder={personType === 'FISICA' ? '000.000.000-00' : '00.000.000/0000-00'} onChange={(e) => setCpfCnpj(e.target.value)} value={cpfCnpj} />
                  </FormGroup>
                </Col>
                <Col>
                  { cpfCnpjInvalido ? <AlertError message={'Cpf Informado é inválido'} >Cpf inválido</AlertError> : '' }
                  {
                    option === New ?
                      (
                        
                          <p>
                            <br/><br/>
                            <Btn attrBtn={{ class: "btn btn-primary btn-lg", color: "secondary", type: "button", onClick: (e) => findPersonByCpfCnpj(e) }}  >Procurar</Btn></p>

                        

                        
                      ) : <FormGroup>
                      <Label className="col-form-label">Date de Início</Label>
                      <Input className="form-control btn-square" type="date" placeholder="dd/mm/aaaa" onChange={(e) => setInitialDate(e.target.value)} value={initialDate} />
                    </FormGroup>
                  }

                </Col>
              </Row>

            
              {
            pesquisouPessoa ? <CompanyPersonForm logoMarca= {logoMarca} companyId={id} data={person} somenteLeitura={somenteLeitura} requestPerson = {requestPerson} /> : ''
          }

</Form>  </CardBody>
         
        
       

        <CardFooter className="text-end">
          {
            option === Edit || option === New ?
              <Btn attrBtn={{
                color: "primary", disabled: !pesquisouPessoa, className: "m-r-15", type: "submit", onClick: (e) => {
                  option === Edit ? props.update(request) : props.save(request);
                }
              }}  >{'Salvar'}</Btn> :
              <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit", onClick: (e) => props.delete(request) }} >{Delete}</Btn>
          }
          <Btn attrBtn={{ color: "secondary", type: "submit", onClick: (e) => props.cancel() }}  >{Cancel}</Btn>
        </CardFooter>
        </Card>

      </Col>
    </Fragment>
  );
};
export default CompanyFormData;
