import React, { Fragment, useState } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, CardHeader, CardBody, Card } from 'reactstrap';
import { Btn, H5 } from '../../../../AbstractElements';
import { CardFooter } from 'reactstrap'
import { Cancel, Delete, Edit, New } from "../../../../Constant";
import dateService from '../../../../Services/utils/date.service';


const AdministrativoClientFormData = (props) => {
  const [id, setId] = useState(props.data.id);
  const  clientId = props.data.client != null ? props.data.client.id : -1; 
  const [name, setName] = useState(props.data.name);
  const [dateOfBirth, setDateOfBirth] = useState(dateService.getDate(props.data.client ? props.data.client.dateOfBirth : null));
  const  initialDate   =  dateService.getDate(props.data.initialDate) ;  
  const [cellphone, setCellphone] = useState(props.data.cellphone);
  const [mail, setMail] = useState(props.data.mail); 
  const somenteLeitura = true;



  const option = props.option;

  var requestClient = {

    id: clientId,
    name: name,
    cellphone: cellphone,
    mail: mail,
    dateOfBirth: dateOfBirth,
    initialDate: initialDate,

  }

  var request = {
    id: id,
    status: 'ACTIVE',
    client: requestClient
  }



  return (
    <Fragment>
      <Col sm="12">
        {props.loading ? (
          <p>Loading...</p>
        ) : props.error ? (
          <p>Error: {props.error.message}</p>
        ) : ''
        }

        <Card className={'card-absolute'}>
          <CardHeader className={'bg-primary'}>
            <H5>{option} Dados cadastrais</H5>
          </CardHeader>
          <CardBody>
            <Form className="theme-form mega-form" id="formCountry" name="formCountry" method="POST">

              <Row>
                <Col md={3}>
                  <FormGroup>
                    <Label className="col-form-label">Id</Label>
                    {option !== New ?
                      <Input className="form-control  disabled" style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}} type="text" placeholder="Id" onChange={(e) => setId(e.target.value)} value={id} readOnly /> :
                      <Input className="form-control  disabled" type="text" placeholder="Código será gerado automaticamente" disabled readOnly />
                    }
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label className="col-form-label"> Name</Label>
                    <Input className={'form-control'}
                      style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
                      disabled={somenteLeitura} type="text"
                      onChange={(e) => { setName(e.target.value); }} value={name} />
                  </FormGroup>
                </Col>


                <Col md={3}><FormGroup>
                  <Label className="col-form-label">Date of Birth</Label>
                  <Input className="form-control"
                    style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
                    type="date" readOnly={somenteLeitura} placeholder="dd/mm/aaaa" onChange={(e) => { setDateOfBirth(e.target.value); }} value={dateOfBirth} />
                </FormGroup>
                </Col>
              </Row>
              <Row>

                <Col md={6}>
                  <FormGroup>
                    <Label className="col-form-label"> E-mail</Label>
                    <Input className={'form-control'}
                      style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
                      disabled={somenteLeitura} type="text"
                      onChange={(e) => { setMail(e.target.value); }} value={mail} />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label className="col-form-label"> Telefone</Label>
                    <Input className={'form-control'}
                      style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
                      disabled={somenteLeitura} type="text"
                      onChange={(e) => { setCellphone(e.target.value); }} value={cellphone} />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody> 
          <CardFooter className="text-end">
            {
              option === Edit || option === New ?
                <Btn attrBtn={{
                  color: "primary", className: "m-r-15", type: "submit", onClick: (e) => {
                    option === Edit ? props.update(request) : props.save(request);
                  }
                }}  >{'Salvar'}</Btn> :
                <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit", onClick: (e) => props.delete(request) }} >{Delete}</Btn>
            }
            <Btn attrBtn={{ color: "secondary", type: "submit", onClick: (e) => props.cancel() }}  >{Cancel}</Btn>
          </CardFooter>
        </Card>

      </Col>
    </Fragment>
  );
};
export default AdministrativoClientFormData;
