import React from 'react';
import { Card, CardBody, Col, Row, } from 'reactstrap';
import { H3, H4, H6, Image, } from '../../../../../AbstractElements';
import downloadService from '../../../../../Services/utils/download.service';
import AvalicaoCliente from './AvaliacaoCliente';
import dateService from '../../../../../Services/utils/date.service';

const InformacoesCliente = () => {
    const cliente = JSON.parse(localStorage.getItem('cliente'));
    return (
        <>
            <Card>

                <CardBody >
                    <Row >
                        <Col md={4}>
                            <div className='profile-nav onhover-dropdown pe-0 py-0'>
                                <Image
                                    attrImage={{
                                        className: 'img-90 img-fluid m-r-20 rounded-circle',
                                        src: downloadService.getImagem(cliente.client.picture),
                                        alt: '',
                                    }}
                                />

                            </div>
                        </Col>
                        <Col md={8}>
                            <Row >
                                <Col> <span >{"Email"}:  <b>{cliente.client.mail}</b></span>
                                </Col>

                            </Row>
                            <Row >
                                <Col >
                                    <span >{"Telefone"}: <b>{cliente.client.cellphone}</b></span>
                                </Col>

                            </Row>
                            <Row >
                                <Col >
                                    <span >{"Data de Nascimento"}: <b>{dateService.getDate(cliente.client.dateOfBirth)}</b></span>
                                </Col>
                            </Row>
                            <Row >
                                <Col >
                                    <span >{"Cliente desde:"}: <b>{dateService.getDate(cliente.initialDate)}</b></span>
                                </Col>
                            </Row>
                        </Col>
                    
                    </Row>

                    <br/>
                    <br/>
                    <Row > <Col >
                        <div className='likes-profile text-left'>
                            <Row >
                                <Col sm="8">
                                    <H3 attrH6={{ className: "mb-0 text-start" }} >{"Pontuação Atual"}: </H3>
                                </Col>
                                <Col sm="4">
                                    <H3><i className='fa fa-heart font-danger'></i> {cliente.points}</H3>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    </Row>



                    <br />
                    <AvalicaoCliente rating={cliente.nps} />
                    <br />

                </CardBody>
            </Card>
        </>
    );
};

export default InformacoesCliente;
